import { createStoreEmitters } from '@empathyco/x-components';
import { resultSuggestionsXStoreModule } from './module';

/**
 * {@link StoreEmitters} For the resultSuggestions module.
 *
 * @internal
 */
export const resultSuggestionsEmitters = createStoreEmitters(resultSuggestionsXStoreModule, {
  ResultSuggestionsSearchRequestChanged: (_state, getters) => getters.request
});
