import { setStatus } from '@empathyco/x-components';
import {
  cancelFetchAndSaveCategories,
  fetchAndSaveCategories
} from './actions/fetch-and-save-categories.action';
import { fetchCategories } from './actions/fetch-categories.action';
import { setUrlParams } from './actions/set-url-params.action';
import { request } from './getters/request.getter';
import { CategoriesXStoreModule } from './types';

/**
 * {@link XStoreModule} For the categories module.
 *
 * @internal
 */
export const categoriesXStoreModule: CategoriesXStoreModule = {
  state: () => ({
    query: '',
    categories: [],
    params: {},
    status: 'initial',
    config: {
      debounceInMs: 200,
      maxItemsToRequest: 5
    }
  }),
  getters: {
    request
  },
  mutations: {
    setStatus,
    setCategories(state, categories) {
      state.categories = categories;
    },
    setQuery(state, query) {
      state.query = query;
    },
    setParams(state, params) {
      state.params = params;
    }
  },
  actions: {
    fetchCategories,
    fetchAndSaveCategories,
    cancelFetchAndSaveCategories,
    setUrlParams
  }
};
