import { reduce } from '@empathyco/x-components';
import { CategoriesRequest, CategoriesXStoreModule, Category } from '../types';

/**
 * Default implementation for the {@link CategoriesActions.fetchCategories}.
 *
 * @param _context - The {@link https://vuex.vuejs.org/guide/actions.html | context} of the actions,
 * provided by Vuex.
 * @param request - The categories request to make.
 *
 * @returns A `void` promise that resolves when it fetches categories.
 *
 * @public
 */
export const fetchCategories: CategoriesXStoreModule['actions']['fetchCategories'] = (
  _context,
  request
) => {
  return request ? doFetchCategories(request) : [];
};

interface RawCategoriesResponse {
  categories: {
    content: RawCategory[];
  };
}

interface RawCategory {
  name: string;
  score: number;
  id: string;
  url: string;
  // additional query results
  code: string;
  catalogType: string;
  path: string[];
}

// TODO Make request in the adapter with proper URL and mappers.
/**
 * Retrieves categories from the API.
 *
 * @param request - The request to fetch categories with.
 * @returns A promise of a list of categories.
 */
function doFetchCategories(request: CategoriesRequest): Promise<Category[]> {
  const snippetConfig = typeof window.initX === 'function' ? window.initX() : window.initX;
  const brand = snippetConfig?.brand as string;
  const channel = snippetConfig?.channel as string;
  const categoryPageRedirectUrl = snippetConfig?.categoryPageRedirectUrl as string;
  const index = `${brand.toLowerCase()}-${channel.toLowerCase()}-products`;

  let env = snippetConfig?.env as string;
  env = env === 'test' || env === 'staging' ? env + '.' : '';

  const url = reduce(
    request,
    (url, name, value) => {
      url.searchParams.set(name, value.toString());
      return url;
    },
    new URL(`https://api.${env}empathy.co/search/v1/query/${index}/categorySearch`)
  );

  return fetch(url.href)
    .then(response => response.json())
    .then(({ categories: { content: rawCategories } }: RawCategoriesResponse) =>
      rawCategories
        .filter(
          /* Remove duplicated categories with the same `name`.
            If we have a category with the same name that has appeared earlier,
            then we can drop the current category. As `findIndex` returns the index
            of the first element matching the condition, we can use it to find if
            there is a category that has appeared earlier than the one that we are iterating. */
          (rawCategory, index) =>
            index ===
            rawCategories.findIndex(
              maybeDuplicateCategory => rawCategory.name === maybeDuplicateCategory.name
            )
        )
        .map(rawCategory => convertRawCategory(rawCategory, categoryPageRedirectUrl))
    );
}

/**
 * Converts RawCategory response to Category.
 *
 * @param rawCategory - Category of response to transform.
 * @param categoryPageRedirectUrl - The Shopsite's category redirect page url.
 *
 * @returns Category.
 */
function convertRawCategory(rawCategory: RawCategory, categoryPageRedirectUrl: string): Category {
  return {
    id: rawCategory.id,
    label: rawCategory.name,
    url: `${categoryPageRedirectUrl}/${rawCategory.code}?source=${rawCategory.catalogType}`
  };
}
