





















































import { MainScrollItem } from '@empathyco/x-components/scroll';
import { Result } from '@empathyco/x-types';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: { MainScrollItem }
})
export default class MobileResultLayout extends Vue {
  @Prop()
  public result!: Result;
}
