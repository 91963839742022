<template>
  <ul class="x-categories-list">
    <li v-for="category in categories" :key="category.id" class="x-categories-list__item">
      <CategoryLink
        #default="categoryScope"
        class="x-categories-list__category"
        :category="category"
      >
        <slot name="category-content" v-bind="categoryScope" />
      </CategoryLink>
    </li>
  </ul>
</template>

<script lang="ts">
  import { State, xComponentMixin } from '@empathyco/x-components';
  import { Component, Vue } from 'vue-property-decorator';
  import { Category as CategoryModel } from '../store/types';
  import { categoriesXModule } from '../x-module';
  import CategoryLink from './category-link.vue';

  @Component({
    mixins: [xComponentMixin(categoriesXModule)],
    components: { CategoryLink }
  })
  export default class CategoriesList extends Vue {
    @State('categories', 'categories')
    public categories!: CategoryModel[];
  }
</script>
