<template>
  <SortDropdown v-if="$x.totalResults" :items="sortValues" :animation="collapseFromTop">
    <template #toggle="{ item }">
      <span class="x-text x-flex-no-shrink">{{ $t('sort.label') }}</span>
      <!--TODO: remove the `x-text` class when this task done:
      https://searchbroker.atlassian.net/browse/EX-4196-->
      <span class="x-text x-dropdown__toggle-selected-value x-flex-no-shrink x-font-color--lead">
        {{ $t(`sort.values.${item || 'default'}`) }}
      </span>
      <ChevronTinyDownIcon />
    </template>

    <template #item="{ item, isHighlighted, isSelected }">
      <!--TODO: remove the `x-text` class when this task done:
      https://searchbroker.atlassian.net/browse/EX-4196-->
      <span class="x-text x-flex-no-shrink" :class="{ 'x-font-color--lead': isSelected }">
        {{ $t(`sort.values.${item || 'default'}`) }}
      </span>
      <CheckTinyIcon v-if="isSelected" />
    </template>
  </SortDropdown>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import { Sort } from '@empathyco/x-types';
  import { SortDropdown } from '@empathyco/x-components/search';
  import { animateScale, CheckTinyIcon, ChevronTinyDownIcon } from '@empathyco/x-components';

  @Component({
    components: {
      SortDropdown,
      CheckTinyIcon,
      ChevronTinyDownIcon
    }
  })
  export default class DesktopSort extends Vue {
    protected collapseFromTop = animateScale();
    public sortValues: Sort[] = ['', 'price asc', 'price desc'];
  }
</script>

<style scoped lang="scss"></style>
