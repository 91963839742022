<template>
  <div v-if="$x.noResults">
    <div class="x-message x-list x-list--wrap x-list--align-center x-list--justify-center">
      <span
        v-if="$x.partialResults.length"
        v-html="$t('partialResults.message', { query: $x.query.search })"
        class="x-text"
      />

      <span
        v-else-if="noResultsAtAll"
        v-html="$t('noResults.message', { query: $x.query.search })"
        class="x-text"
      />

      <RenderlessExtraParams
        v-else
        #default="{ value: currentContentType, updateValue }"
        name="contentType"
      >
        <div class="x-list x-list--gap-06">
          <span
            v-html="
              $t('noResults.messageWithContentTypeResults', {
                query: $x.query.search,
                contentType: $t(`contentTypes.${currentContentType}`)
              })
            "
            class="x-text"
          />
          <div
            class="x-list x-list--wrap x-list--gap-06 x-list--justify-center x-list--align-start"
          >
            <button
              v-for="(totalResults, contentType) in otherContentTypes"
              :key="contentType"
              @click="updateValue(contentType)"
              class="x-button x-button--secondary"
            >
              {{ $t(`contentTypes.${contentType}`) }} ({{ totalResults }})
            </button>
          </div>
        </div>
      </RenderlessExtraParams>
    </div>

    <template v-if="noResultsAtAll">
      <NoResultsLinks class="x-padding--top-07 x-padding--bottom-07" />
      <h2 v-if="$x.recommendations.length" class="x-title2">{{ $t('recommendations.title') }}</h2>
      <CustomRecommendations class="x-margin--top-04" maxItemsToRender="8" />
    </template>
  </div>
</template>

<script lang="ts">
  import { Getter, map, objectFilter, State, XInject } from '@empathyco/x-components';
  import { RenderlessExtraParams } from '@empathyco/x-components/extra-params';
  import { Component, Vue } from 'vue-property-decorator';
  import { ContentType, ContentTypeSearchState } from '../x-modules/content-types/index';
  import NoResultsLinks from './no-results-links.vue';
  import CustomRecommendations from './results/custom-recommendations.vue';

  @Component({
    components: {
      CustomRecommendations,
      NoResultsLinks,
      RenderlessExtraParams
    }
  })
  export default class NoResultsMessage extends Vue {
    @XInject('noResultsAtAll')
    public noResultsAtAll!: boolean;

    @State('contentTypes', 'results')
    public contentTypeResults!: Record<ContentType, ContentTypeSearchState>;

    @Getter('contentTypes', 'activeContentType')
    public selectedContentType!: ContentType;

    protected get otherContentTypes(): Record<ContentType, number> {
      return map(
        objectFilter(
          this.contentTypeResults,
          (key, { totalResults }) => key !== this.selectedContentType && totalResults > 0
        ),
        (_key, { totalResults }) => totalResults
      );
    }
  }
</script>
