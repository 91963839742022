import { ApolloClient, QueryOptions } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { Product, Availability, Price, Promotion } from '../apollo/apollo-bsh-types';
import { createApolloClient } from '../apollo/apollo-client';
import { sparepartsQuery } from '../apollo/product.query';
import { ContentType } from '../x-modules/content-types/store/types';
import { EmpathyProductResult, Stock } from './demo-result.mapper';

export class CustomApolloHttpClient {
  private client: ApolloClient<NormalizedCacheObject>;
  private initX: any = window.initX;
  private brand: string = this.initX['brand'];
  private channel: string = this.initX['channel'];
  private language: string = this.initX['lang'];
  private country: string = this.initX['country'];
  private baseUrl: string = this.initX['baseUrl'];
  private detailSeo: string = this.initX['seoName'];
  private mockGraphqlResponse: boolean = this.initX['mockGraphqlResponse'];

  public constructor() {
    const options = {
      shopContext: {
        country: this.country,
        brand: this.brand,
        language: this.language
      }
    };
    this.client = createApolloClient(options);
  }

  private buildQuery(searchTerm: string): QueryOptions {
    return {
      query: sparepartsQuery,
      variables: {
        productId: searchTerm
      }
    };
  }

  private extractMatNumber(searchTerm: string): string | undefined {
    const regex = /[\d]{8}/g;
    const match = searchTerm.match(regex);
    return match ? match[0] : undefined;
  }

  async get<T>(contentType: ContentType, searchTerm: string): Promise<T> {
    const matNumber = this.extractMatNumber(searchTerm);
    if (matNumber) {
      const query = this.buildQuery(matNumber);
      switch (contentType) {
        case 'spareparts':
          try {
            const result = await this.client.query(query);
            return this.transformResult(result);
          } catch (err) {
            console.log(`Unable to query for sparepart: ${searchTerm}`);
            if (this.mockGraphqlResponse) {
              return this.wrapEmpathyResult(this.createMockObject());
            }
          }
      }
    }
    return this.wrapEmpathyResult();
  }

  private createMockObject(): EmpathyProductResult[] {
    const today = new Date();
    const yesterday = new Date().setDate(today.getDate() - 1);
    const tomorrow = new Date().setDate(today.getDate() + 1);
    const mock: EmpathyProductResult = {
      // x-types Identifiable
      id: 'siemens-B2C-nl-NL-00418280',
      externalId: 'siemens-B2C-nl-NL-00418280',
      code: '00418280',
      name: '00418280',
      brand: 'SIEMENS',
      type: 'MAT',
      release: {
        start: new Date(yesterday),
        end: new Date(tomorrow)
      },
      rating: {
        averageOverallRating: 0,
        totalReviewCount: 0
      },
      availability: {
        visibility: 'SHOP',
        stockIndicator: 1,
        colorCode: 'GREEN',
        sellable: true
      },
      descriptions: [],
      detailUrls: ['https://www.bosch-home.es/catalogo-electrodomesticos/00418280'],
      url: 'https://www.bosch-home.es/catalogo-electrodomesticos/00418280',
      price: {
        price: 99.99,
        originalPrice: 16.4
      },
      imageUrl: 'https://media3.bsh-group.com/Product_Shots/MCSA01614362_00418280_def.png',
      productHeaders: {
        header1: 'Header 1',
        header2: 'Header 2',
        header3: 'Header 3',
        header4: 'Header 4',
        header5: 'Header 5',
        header6: 'Header 6'
      },
      promotion: {
        title: 'Mock Promotion',
        text: 'Mock marketing text'
      }
    };
    return [mock];
  }

  private wrapEmpathyResult(content?: EmpathyProductResult[]): any {
    return {
      banner: {
        content: []
      },
      catalog: {
        content: content ?? [],
        numFound: content?.length ?? 0
      },
      direct: {
        content: []
      },
      promoted: {
        content: []
      }
    };
  }

  private transformResult(result: any): any {
    const product = result.data?.product;
    const availability = result.data?.availability;
    const price = result.data?.price;
    const promotion = result.data?.promotion;
    const content =
      product?.type === 'MAT'
        ? [this.transformProduct(product, availability, price, promotion)]
        : [];
    return this.wrapEmpathyResult(content);
  }

  private transformProduct(
    qlProduct: Product,
    qlAvailability: Availability,
    qlPrice: Price,
    qlPromotion: Promotion
  ): EmpathyProductResult {
    const detailUrl = `${this.baseUrl}${this.detailSeo}/${qlProduct.id}`;
    const productImage = qlProduct.assets?.images?.find(
      image => image.category === 'bsh:category.product-shot.standard-stp'
    )?.url;
    const channelStart = qlProduct.salesInformation?.release?.find(
      entry =>
        entry.brand?.toLocaleLowerCase() === this.brand.toLocaleLowerCase() &&
        entry.channel?.toLocaleLowerCase() === this.channel.toLocaleLowerCase()
    );
    const startSales = qlProduct.salesInformation?.startSales ?? channelStart?.start;
    const endSales = qlProduct.salesInformation?.endSales ?? channelStart?.end;
    return {
      id: `${this.brand}-${this.channel}-${this.language}_${this.country}-${qlProduct.id}`,
      externalId: `${this.brand}-${this.channel}-${this.language}_${this.country}-${qlProduct.id}`,
      code: qlProduct.id,
      name: qlProduct.id,
      brand: this.brand,
      type: 'MAT',
      release: {
        start: startSales,
        end: endSales
      },
      rating: {
        averageOverallRating: 0,
        totalReviewCount: 0
      },
      availability: {
        visibility: qlAvailability?.visibility,
        stockIndicator: qlAvailability?.stockIndicator,
        colorCode: qlAvailability.status as Stock,
        sellable: qlAvailability.sellable
      },
      descriptions: [],
      detailUrls: [detailUrl],
      url: detailUrl,
      price: {
        originalPrice: qlPromotion?.price?.price?.amount ? qlPrice.price.amount : 0,
        price: qlPromotion?.price?.price?.amount ?? qlPrice?.price?.amount
      },
      imageUrl: productImage ? productImage : '',
      productHeaders: {
        header1: qlProduct.header.filter(e => e.level === 1)[0]?.value,
        header2: qlProduct.header.filter(e => e.level === 2)[0]?.value,
        header3: qlProduct.header.filter(e => e.level === 3)[0]?.value,
        header4: qlProduct.header.filter(e => e.level === 4)[0]?.value,
        header5: qlProduct.header.filter(e => e.level === 5)[0]?.value,
        header6: qlProduct.header.filter(e => e.level === 6)[0]?.value
      },
      promotion: qlPromotion ? { title: qlPromotion.title, text: qlPromotion.details } : undefined
    };
  }
}
