import { setStatus } from '@empathyco/x-components';
import {
  cancelFetchAndSaveResultSuggestions,
  fetchAndSaveResultSuggestions
} from './actions/fetch-and-save-result-suggestions.action';
import { setUrlParams } from './actions/set-url-params.action';
import { fetchResultSuggestions } from './actions/fetch-result-suggestions.action';
import { request } from './getters/request.getter';
import { ResultSuggestionsXStoreModule } from './types';

/**
 * {@link XStoreModule} For the resultSuggestions module.
 *
 * @internal
 */
export const resultSuggestionsXStoreModule: ResultSuggestionsXStoreModule = {
  state: () => ({
    query: '',
    resultSuggestions: [],
    redirections: [],
    params: {},
    status: 'initial',
    config: {
      debounceInMs: 400,
      maxItemsToRequest: 6
    }
  }),
  getters: {
    request
  },
  mutations: {
    setParams(state, { contentType, ...params }) {
      state.params = params;
    },
    setStatus,
    setResultSuggestions(state, resultSuggestions) {
      state.resultSuggestions = resultSuggestions;
    },
    setRedirections(state, redirections) {
      state.redirections = redirections;
    },
    setQuery(state, query) {
      state.query = query;
    }
  },
  actions: {
    fetchResultSuggestions,
    fetchAndSaveResultSuggestions,
    cancelFetchAndSaveResultSuggestions,
    setUrlParams
  }
};
