






























import { Component } from 'vue-property-decorator';
import BaseResult from './base-result';

@Component
export default class EnergyLabel extends BaseResult {}
