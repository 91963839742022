












import { BaseSuggestion, Getter } from '@empathyco/x-components';
import { Suggestion, Result } from '@empathyco/x-types';
import { Component, Prop, Vue } from 'vue-property-decorator';

export declare interface BSHIdentifierSuggestions extends Suggestion {
  type: string;
}

@Component({
  components: { BaseSuggestion }
})
export default class IdentifierSuggestion extends Vue {
  @Getter('querySuggestions', 'normalizedQuery')
  public query!: string;

  @Prop({ required: true })
  protected identifierResult!: Result;

  protected get suggestion(): BSHIdentifierSuggestions {
    return {
      modelName: 'QuerySuggestion',
      query: this.identifierResult.identifier.value,
      facets: [],
      key: this.identifierResult.identifier.value,
      type: this.identifierResult.type
    };
  }
}
