import { XPlugin } from '@empathyco/x-components';
import { ResultSuggestionsXStoreModule } from '../types';

/**
 * Default implementation for the {@link ResultSuggestionsActions.fetchResultSuggestions}.
 *
 * @param _context - The {@link https://vuex.vuejs.org/guide/actions.html | context} of the actions,
 * provided by Vuex.
 * @param request - The result suggestions request to make.
 *
 * @returns A Promise of result suggestions response that resolves when it fetches result
 * suggestions response.
 *
 * @public
 */
// eslint-disable-next-line max-len
export const fetchResultSuggestions: ResultSuggestionsXStoreModule['actions']['fetchResultSuggestions'] =
  (_context, request) => {
    return request
      ? XPlugin.adapter.search(request, { requestId: 'result-suggestions' })
      : { results: [], spellcheck: '', redirections: [] };
  };
