






















import { AutoProgressBar } from '@empathyco/x-components';
import { Redirection } from '@empathyco/x-components/search';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({ components: { AutoProgressBar, Redirection } })
export default class CustomRedirection extends Vue {}
