<template>
  <div class="x-background--neutral-100 x-list__item--expand x-list x-list--vertical">
    <div
      class="
        x-list x-list--horizontal x-list--align-center
        x-border-width--bottom-01
        x-border-color--neutral-70
        x-padding--07 x-padding--right-11 x-padding--left-11
      "
    >
      <span class="x-title1 x-margin--right-auto">{{ $t('facetsPanel.title') }}</span>
      <BaseIdModalClose class="x-button--ghost x-button--ghost-end" modalId="right-aside">
        <CrossIcon />
      </BaseIdModalClose>
    </div>
    <div
      class="x-scroll x-list__item--expand x-padding--05 x-padding--left-11 x-padding--right-09"
      style="overflow-y: scroll"
    >
      <CustomFacets v-if="$x.totalResults > 0" class="x-list--gap-02" />
    </div>
    <div
      class="
        x-list x-list--horizontal
        x-border-width--top-01
        x-border-color--neutral-70
        x-padding--11 x-padding--top-07 x-padding--bottom-04
      "
    >
      <BaseIdModalClose
        class="x-button--animated x-button--tertiary x-list__item--expand"
        modalId="right-aside"
      >
        {{ $t('facetsPanel.viewResults', { totalResults: $x.totalResults }) }}
      </BaseIdModalClose>
    </div>
  </div>
</template>

<script lang="ts">
  import { BaseIdModalClose, CrossIcon } from '@empathyco/x-components';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import CustomFacets from '../facets/custom-facets.vue';

  @Component({
    components: {
      BaseIdModalClose,
      CrossIcon,
      CustomFacets
    }
  })
  export default class DesktopAside extends Vue {}
</script>
