





































import {
  BaseGrid,
  BaseVariableColumnGrid,
  infiniteScroll,
  StaggeredFadeAndSlide
} from '@empathyco/x-components';
import { MainScrollItem } from '@empathyco/x-components/scroll';
import {
  Banner,
  BannersList,
  Promoted,
  PromotedsList,
  ResultsList
} from '@empathyco/x-components/search';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
// eslint-disable-next-line max-len
import ContentTypeCarousel from '../../x-modules/content-types/components/content-type-carousel.vue';
// eslint-disable-next-line max-len
import ContentTypesCarouselsList from '../../x-modules/content-types/components/content-types-carousels-list.vue';
import ContentResult from '../result/content-result.vue';
import ProductResult from '../result/product-result.vue';
import SimplifiedProductResult from '../result/simplified-product-result.vue';
import SparePartResult from '../result/spare-part-result.vue';

@Component({
  components: {
    Banner,
    BannersList,
    BaseGrid,
    BaseVariableColumnGrid,
    ContentResult,
    ContentTypeCarousel,
    ContentTypesCarouselsList,
    MainScrollItem,
    Promoted,
    PromotedsList,
    ProductResult,
    ResultsList,
    SimplifiedProductResult,
    SparePartResult
  },
  directives: {
    'infinite-scroll': infiniteScroll
  }
})
export default class CustomResultsList extends Vue {
  protected staggeredFadeAndSlide = StaggeredFadeAndSlide;

  protected get grid(): string {
    return this.$x.device === 'mobile' ? 'BaseGrid' : 'BaseVariableColumnGrid';
  }
}
