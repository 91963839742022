import { CrossFade, XOn } from '@empathyco/x-components';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Result } from '@empathyco/x-types';

@Component
export default class BaseResult extends Vue {
  @Prop()
  public result!: Result;

  protected imageAnimation = CrossFade;

  protected showDesktopOneColumnCard = false;

  @XOn('UserClickedColumnPicker')
  setColumns(columns: number): void {
    this.showDesktopOneColumnCard = columns === 1;
  }
}
