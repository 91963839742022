<template>
  <component
    :is="
      $x.device === 'mobile'
        ? 'MobileResultLayout'
        : showDesktopOneColumnCard
        ? 'DesktopOneColumnResultLayout'
        : 'DesktopResultLayout'
    "
    :result="result"
  >
    <template #labels>
      <!--  Labels  -->
      <div class="x-list x-list--wrap x-list--gap-02 x-font-color--neutral-100 x-font-size--04">
        <span
          v-if="result.productSalesType"
          class="x-background--accent"
          :class="
            $x.device === 'mobile'
              ? 'x-padding--03 x-padding--top-01 x-padding--bottom-01'
              : 'x-padding--05 x-padding--top-02 x-padding--bottom-02'
          "
        >
          {{
            result.productSalesType === 'NEW'
              ? $t('result.newProductLabel')
              : $t('result.brandNewProductLabel')
          }}
        </span>
        <span
          v-if="result.promoLabel"
          class="x-background--accent"
          :class="
            $x.device === 'mobile'
              ? 'x-padding--03 x-padding--top-01 x-padding--bottom-01'
              : 'x-padding--05 x-padding--top-02 x-padding--bottom-02'
          "
        >
          {{ $t('result.promoLabel') }}
        </span>
      </div>
    </template>

    <template #image>
      <BaseResultLink class="x-list x-list--vertical x-flex-1" :result="result">
        <!--  Image  -->
        <BaseResultImage
          :style="$x.device === 'mobile' ? 'height: 150px' : 'height: 200px'"
          :result="result"
          :animation="imageAnimation"
        >
          <template #placeholder>
            <BasePlaceholderImage />
          </template>
          <template #fallback>
            <BaseFallbackImage />
          </template>
        </BaseResultImage>
      </BaseResultLink>
    </template>

    <template #flex-campaign>
      <!--  Wifi icon  -->
      <!-- DON'T REMOVE: Classes `icon` and `icon-wifi` are from BSH. -->
      <span v-if="result.isHomeConnectableUrl" class="x-icon x-icon--xl icon icon-wifi"></span>
    </template>

    <!--  Name  -->
    <template #name>
      <BaseResultLink :result="result">
        <h1
          v-html="result.name"
          class="x-title2"
          :class="$x.device === 'desktop' ? '' : 'x-title3 x-line-height--tight'"
          style="word-break: break-word; font-size: 20px"
        />
      </BaseResultLink>
    </template>

    <!--  ID  -->
    <template #id>
      <BaseResultLink :result="result">
        <h2 class="x-text x-text--bold x-uppercase">
          {{ result.matCode ? `${result.id} - ${result.matCode}` : result.id }}
        </h2>
      </BaseResultLink>
    </template>

    <template #rating>
      <!--    Rating    -->
      <BaseResultRating
        v-if="!!result.totalReviewCount && result.type !== 'MAT'"
        #default="{ rating }"
        class="x-font-color--lead x-list x-list--horizontal x-list--align-center x-list--gap-02"
        :result="result"
        :link="result.url + '#/Tabs=section-reviews/'"
      >
        <BaseRating :value="rating" />
        <span
          class="x-font-color--neutral-10"
          :class="{ 'x-font-weight--bold ': $x.device === 'desktop' }"
        >
          {{ Number(rating).toFixed(1) }}
        </span>
        <span class="x-small x-font-color--lead">({{ result.totalReviewCount }})</span>
      </BaseResultRating>
    </template>

    <template #stock>
      <!--    Stock    -->
      <div
        v-if="result.catalog !== 'MARKETING'"
        class="x-small x-list x-list--horizontal x-list--gap-03"
        :class="{
          'x-font-color--enable': result.stock === 'GREEN',
          'x-font-color--warning': result.stock === 'YELLOW',
          'x-font-color--disable': result.stock === 'RED'
        }"
      >
        <!-- DON'T REMOVE: Classes `icon` and `icon-stock` are from BSH. -->
        <span class="x-icon icon icon-stock"></span>
        <span>
          {{
            result.stock === 'GREEN'
              ? $t('result.available')
              : result.stock === 'YELLOW'
              ? $t('result.fewStock')
              : $t('result.notAvailable')
          }}
        </span>
      </div>
    </template>

    <!--    Price    -->
    <template #original-price>
      <BaseResultPreviousPrice
        v-if="result.price.value && result.price.originalValue && result.catalog !== 'MARKETING'"
        format="i.iii,dd €"
        :result="result"
        class="x-font-color--neutral-70 x-line-through"
        :class="$x.device === 'mobile' ? 'x-font-size--4' : 'x-font-size--06'"
      />
    </template>
    <template #price>
      <BaseResultCurrentPrice
        v-if="result.price.value && result.catalog !== 'MARKETING'"
        :result="result"
        :class="[
          result.price.hasDiscount ? 'x-font-color--accent' : 'x-font-color--lead',
          { 'x-font-size--06': $x.device === 'mobile' }
        ]"
        class="x-title1 x-text--bold"
      />
    </template>

    <template #energy>
      <!--   Energy Label   -->
      <EnergyLabel :result="result" />
    </template>

    <template #promotion>
      <span v-html="result.pricePromotion" class="x-small x-font-weight--bold"></span>
    </template>

    <template #add-to-cart>
      <!--  Add to cart  -->
      <BaseEventButton
        v-if="result.matCode"
        @click="redirectToProductDetails"
        :events="toProductDetailsEvent"
        class="x-button x-button--tertiary x-button--animated x-list__item--stretch"
      >
        <span>
          {{ $t('result.productDetails') }}
        </span>
      </BaseEventButton>
      <BaseEventButton
        v-else-if="result.catalog === 'MARKETING'"
        @click="redirectToFindRetailer"
        :events="findLocalRetailerEvent"
        class="x-button x-button--tertiary x-button--animated x-list__item--stretch"
      >
        <!-- DON'T REMOVE: Classes `icon` and `icon-mappin` are from BSH. -->
        <span class="x-icon icon icon-mappin"></span>
        <span>
          {{ $t('result.findLocalRetailer') }}
        </span>
      </BaseEventButton>

      <CustomResultAddToCart
        v-else-if="result.catalog === 'SHOP'"
        :result="result"
        cssClass="x-button--primary x-button--animated x-button--animated-icon
        x-list__item--stretch"
      >
        <CartIcon />
        <span>{{ $t('result.addToCart') }}</span>
      </CustomResultAddToCart>
    </template>

    <template #description>
      <!--  Description  -->
      <p class="x-text x-text--secondary x-line-clamp--4">
        {{ result.description }}
      </p>
    </template>
  </component>
</template>

<script lang="ts">
  import {
    BaseEventButton,
    BaseFallbackImage,
    BasePlaceholderImage,
    BaseRating,
    BaseResultRating,
    BaseResultCurrentPrice,
    BaseResultPreviousPrice,
    BaseResultImage,
    BaseResultLink,
    CartIcon
  } from '@empathyco/x-components';
  import { Component } from 'vue-property-decorator';
  import BaseResult from './base-result';
  import CustomResultAddToCart from './custom-result-add-to-cart.vue';
  import DesktopOneColumnResultLayout from './desktop-one-column-result-layout.vue';
  import DesktopResultLayout from './desktop-result-layout.vue';
  import EnergyLabel from './energy-label.vue';
  import MobileResultLayout from './mobile-result-layout.vue';

  @Component({
    components: {
      BaseEventButton,
      BaseFallbackImage,
      BasePlaceholderImage,
      BaseRating,
      BaseResultRating,
      BaseResultCurrentPrice,
      BaseResultPreviousPrice,
      BaseResultImage,
      BaseResultLink,
      CartIcon,
      CustomResultAddToCart,
      DesktopOneColumnResultLayout,
      DesktopResultLayout,
      EnergyLabel,
      MobileResultLayout
    }
  })
  export default class ProductResult extends BaseResult {
    private initX: any = window.initX;
    private onlineDealerLocatorUrl: string = this.initX['onlineDealerLocatorUrl'];

    protected findLocalRetailerEvent = { UserClickedResultFindLocalRetailer: this.result };

    protected redirectToFindRetailer(): void {
      const url = `${this.onlineDealerLocatorUrl}?product=${this.result.id
        ?.toString()
        .toUpperCase()}`;
      window.location.assign(url);
    }

    protected toProductDetailsEvent = { UserClickedResultProductDetails: this.result };
    protected redirectToProductDetails(): void {
      window.location.assign(this.result.url);
    }
  }
</script>
