import { ResultSuggestionsXStoreModule } from '../types';

/**
 * Default implementation for the {@link ResultSuggestionsGetters.request} getter.
 *
 * @param state - Current {@link https://vuex.vuejs.org/guide/state.html | state} of the result
 * suggestions module.
 *
 * @returns The result suggestions request to fetch data from the API.
 *
 * @public
 */
export const request: ResultSuggestionsXStoreModule['getters']['request'] = ({
  query,
  params,
  config
}) => {
  return query.trim()
    ? {
        query,
        rows: config.maxItemsToRequest,
        start: 0,
        ...params,
        contentType: 'products'
      }
    : null;
};
