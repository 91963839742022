











import { StaggeredFadeAndSlide } from '@empathyco/x-components';
import { QuerySuggestions } from '@empathyco/x-components/query-suggestions';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    QuerySuggestions
  }
})
export default class CustomQuerySuggestions extends Vue {
  public suggestionsAnimation = StaggeredFadeAndSlide;
}
