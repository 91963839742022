import { XModule } from '@empathyco/x-components';
import { contentTypesEmitters, contentTypesXStoreModule, ContentTypesXStoreModule } from './store';
import { contentTypesWiring } from './wiring';

export type ContentTypesXModule = XModule<ContentTypesXStoreModule>;

export const contentTypesXModule: ContentTypesXModule = {
  name: 'contentTypes',
  storeModule: contentTypesXStoreModule,
  storeEmitters: contentTypesEmitters,
  wiring: contentTypesWiring
};
