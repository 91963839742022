
































import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CrossTinyIcon,
  PlusIcon,
  SlidingPanel,
  StaggeredFadeAndSlide,
  TagIcon
} from '@empathyco/x-components';
import { RelatedTags } from '@empathyco/x-components/related-tags';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    CrossTinyIcon,
    PlusIcon,
    RelatedTags,
    SlidingPanel,
    TagIcon
  }
})
export default class CustomRelatedTags extends Vue {
  protected relatedTagsAnimation = StaggeredFadeAndSlide;
}
