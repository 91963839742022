<template>
  <BaseEventsModal
    v-on="$listeners"
    class="x-relative-modal"
    v-bind="$attrs"
    :style="{ '--x-top': top }"
    :class="{
      'x-modal--is-scrolling-down': mainScroll && mainScroll.direction === 'DOWN'
    }"
    :disableHtmlScroll="false"
  >
    <GlobalEvents @resize="throttledUpdateTop" target="window" />
    <slot />
  </BaseEventsModal>
</template>

<script lang="ts">
  import {
    BaseEventsModal,
    Dictionary,
    State,
    throttleFunction,
    XOn
  } from '@empathyco/x-components';
  import { ScrollComponentState } from '@empathyco/x-components/scroll';
  import GlobalEvents from 'vue-global-events';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

  @Component({
    inheritAttrs: false,
    components: {
      GlobalEvents,
      BaseEventsModal
    }
  })
  export default class AutoTopModal extends Vue {
    @Prop({ default: '.x-header-target' })
    public selector!: string;

    @Prop({ default: 50 })
    public resizeThrottleMs!: number;

    protected top = '0px';

    @State('scroll', 'data')
    public scrollData!: Dictionary<ScrollComponentState>;

    protected get throttledUpdateTop(): VoidFunction {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      return throttleFunction(this.updateTop, this.resizeThrottleMs);
    }

    protected get mainScroll(): ScrollComponentState | null {
      return this.scrollData['main-scroll'];
    }

    mounted(): void {
      this.updateTop();
    }

    @Watch('selector', { immediate: true })
    @XOn('UserOpenXProgrammatically')
    updateTop(): void {
      if (this.selector) {
        const targetElement = Array.from(
          document.querySelectorAll<HTMLElement>(this.selector)
          // eslint-disable-next-line @typescript-eslint/unbound-method
        ).find(this.isVisible);
        if (targetElement) {
          const targetPosition = targetElement.getBoundingClientRect().bottom;
          this.top = targetPosition < 0 ? '0px' : `${targetPosition}px`;
        } else {
          this.top = '0px';
        }
      } else {
        this.top = '0px';
      }
    }

    protected isVisible(element: HTMLElement): boolean {
      /* Uses `getClientRects` to check if the element is rendered, as checking styles with
      `getComputedStyle` would make us transverse the ancestors as one of them might be the one
      that has `display: hidden` instead of the target element. */
      return element.getClientRects().length > 0;
    }
  }
</script>

<style lang="scss" scoped>
  // Adding `.x-modal` to have more specifity, as it conflicts with the base modal styles

  .x-modal {
    transition: all 0.3s ease-in-out;
    &--is-scrolling-down {
      transform: translateY(calc(-1 * var(--x-top)));
    }

    &.x-relative-modal {
      top: var(--x-top);
    }

    &.x-animate-clip-path--enter-active ::v-deep .x-empathize {
      transition-delay: 0.4s !important;
    }
    &.x-animate-clip-path--leave-active {
      transition-delay: 0.4s !important;
    }
  }
</style>
<style>
  html {
    overflow: visible !important;
  }
</style>
