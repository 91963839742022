import {
  createWiring,
  namespacedWireCommit,
  namespacedWireDispatch,
  namespacedWireDispatchWithoutPayload
} from '@empathyco/x-components';

const wireDispatch = namespacedWireDispatch('contentTypes');
const wireDispatchWithoutPayload = namespacedWireDispatchWithoutPayload('contentTypes');
const wireCommit = namespacedWireCommit('contentTypes');

const searchAndSaveWire = wireDispatch('searchAndSave');
const loadUrlParamsWire = wireDispatch('loadUrlParams');
const cancelSearchAndSaveWire = wireDispatchWithoutPayload('cancelSearchAndSave');
const setContentTypesQueryWire = wireCommit('setQuery');
const setContentTypesExtraParamsWire = wireCommit('setExtraParams');
const setRelatedTagsWire = wireCommit('setRelatedTags');

/**
 * Wiring configuration for the {@link ContentTypesXModule | contentTypes module}.
 *
 * @internal
 */
export const contentTypesWiring = createWiring({
  ContentTypesSearchRequestChanged: {
    searchAndSaveWire
  },
  UserAcceptedAQuery: {
    setContentTypesQueryWire
  },
  UserClearedQuery: {
    setContentTypesQueryWire,
    cancelSearchAndSaveWire
  },
  ExtraParamsChanged: {
    setContentTypesExtraParamsWire
  },
  ParamsLoadedFromUrl: {
    loadUrlParamsWire
  },
  SelectedRelatedTagsChanged: {
    setRelatedTagsWire
  }
});
