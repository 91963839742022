

















import { LocationProvider } from '@empathyco/x-components';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import DesktopSelectedFilters from './desktop/desktop-selected-filters.vue';
import NoResultsMessage from './no-results-message.vue';
import CustomPartialResults from './results/custom-partial-results.vue';
import CustomRedirection from './results/custom-redirection.vue';
import CustomResultsList from './results/custom-results-list.vue';
import SpellcheckMessage from './spellcheck-message.vue';

@Component({
  components: {
    CustomPartialResults,
    CustomRedirection,
    DesktopSelectedFilters,
    LocationProvider,
    NoResultsMessage,
    CustomResultsList,
    SpellcheckMessage
  }
})
export default class Main extends Vue {}
