























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Sort } from '@empathyco/x-types';
import { SortDropdown } from '@empathyco/x-components/search';
import { animateScale, CheckTinyIcon, ChevronTinyDownIcon } from '@empathyco/x-components';

@Component({
  components: {
    SortDropdown,
    CheckTinyIcon,
    ChevronTinyDownIcon
  }
})
export default class DesktopSort extends Vue {
  protected collapseFromTop = animateScale();
  public sortValues: Sort[] = ['', 'price asc', 'price desc'];
}
