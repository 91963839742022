






import { ChevronUpIcon } from '@empathyco/x-components';
import { ScrollToTop } from '@empathyco/x-components/scroll';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    ScrollToTop,
    ChevronUpIcon
  }
})
export default class CustomScrollToTop extends Vue {}
