import { DeepPartial, Dictionary } from '@empathyco/x-adapter';
import { Result, ResultPrice } from '@empathyco/x-types';
import { ContentType } from '../x-modules/content-types/store/types';

export type Stock = 'GREEN' | 'YELLOW' | 'RED';
export type ProductSalesType = 'NEW' | 'BRAND_NEW' | undefined;
export type ProductType = 'VIB' | 'BVIB' | 'MAT';
export type DocumentType = 'ium' | 'faq' | 'other';
type ResponseType = 'VIBMAT' | 'VIBKI' | 'CONTENT';

declare module '@empathyco/x-types' {
  interface Result {
    brand: string;
    matCode?: string;
    stock: Stock;
    energyLabelIcon: string;
    energyLabel: string;
    energyClassRange: string;
    energyDatasheet: string;
    description: string;
    totalReviewCount: number;
    isHomeConnectableUrl: boolean;
    buyable: boolean;
    catalog: string;
    productSalesType: string;
    promoLabel: boolean;
    pricePromotion: string;
    contentType: ContentType;
    content: string;
    documentType: DocumentType;
  }
}

export interface EmpathyResult
  extends EmpathyProductResult,
    EmpathyEnumberResult,
    EmpathyContentResult {}

export interface EmpathyProductResult {
  id: string;
  externalId: string;
  code: string;
  name: string;
  type: ProductType;
  brand: string;
  locale?: string;
  release: {
    start: Date;
    end: Date;
  };
  rating: {
    averageOverallRating: number;
    totalReviewCount: number;
  };
  availability: {
    visibility: string;
    stockIndicator: number;
    colorCode: Stock;
    sellable: boolean;
  };
  descriptions: string[];
  url: string;
  detailUrls: string[];
  price: {
    price: number;
    originalPrice?: number;
    rrp?: number;
  };
  imageUrl: string;
  productHeaders: {
    header1: string;
    header2: string;
    header3: string;
    header4: string;
    header5: string;
    header6: string;
  };
  promotion?: {
    title: string;
    text: string;
  };
  energyLabelClassRange?: string;
  energyLabelDataSheet?: string;
  energyLabelIconUrl?: string;
  energyLabelUrl?: string;
  highlights?: string[];
  relatedMat?: string;
}

export interface EmpathyEnumberResult {
  variantId: string;
  productId: string;
  imageUrl: string;
}

export interface EmpathyContentResult {
  id: string;
  title: string;
  url: string;
  description: string;
  content: string;
  documentType: string;
  products: string[];
}

const urlSizeRegex = /(\{width\}|\{height\})/g;

/**
 * This mapper adapts the response from the api to X Components.
 *
 * @param rawResult - This is the raw response form the API.
 * @param result - This is the Empathy result handle by the app.
 * @param context - The context of the mapper that includes the request.
 *
 * @returns A new object which can be handled by XComponents.
 */
export function resultMapper(
  rawResult: EmpathyResult,
  result: Result,
  { request }: { request: Dictionary }
): Result {
  try {
    switch (identifyResponseType(rawResult)) {
      case 'VIBMAT':
        return transformProductFeedResponse(rawResult, result, request);
      case 'VIBKI':
        return transformENumberFeedResponse(rawResult, result, request);
      case 'CONTENT':
        return transformContentFeedResponse(rawResult, result, request);
    }
  } catch (err) {
    console.error(err);
    return result;
  }
}

/**
 * Identify document type of response.
 *
 * @param response - The document to identify the type for.
 * @returns Response type of document.
 */
function identifyResponseType(response: EmpathyResult): ResponseType {
  if (response.type) {
    return 'VIBMAT';
  } else if (response.variantId) {
    return 'VIBKI';
  } else {
    return 'CONTENT';
  }
}

/**
 * Transforms product response.
 *
 * @param rawResult - This is the raw response form the API.
 * @param result - This is the Empathy result handle by the app.
 * @param request - The search request.
 *
 * @returns A new object which can be handled by XComponents.
 */
function transformProductFeedResponse(
  rawResult: EmpathyProductResult,
  result: Result,
  request: Dictionary
): Result {
  return Object.assign<Result, DeepPartial<Result>>(result, {
    // x-types Identifiable
    id: rawResult.code,
    // x-types Taggable
    // see adapter.setResultTrackingConfig(...)
    // x-types Result
    type: rawResult.type,
    images: [rawResult.imageUrl?.replaceAll(urlSizeRegex, '600')],
    name: getName(rawResult),
    price: getPrice(rawResult),
    rating: { value: rawResult.rating?.averageOverallRating },
    identifier: { value: rawResult.code },
    url: getUrl(rawResult),
    isWishlisted: undefined,
    // extended result
    brand: getConfigParameter('brand') ?? 'no_brand',
    matCode: rawResult.relatedMat,
    // Products, E-Number, WebContent, Spareparts
    contentType: request.contentType,
    // Rating (average rating already part of x-types result)
    totalReviewCount: rawResult.rating?.totalReviewCount,
    // availability (price already part of x-types result)
    stock: rawResult.availability.colorCode,
    buyable: rawResult.availability.sellable,
    catalog: rawResult.availability.visibility,
    // Promotions
    promoLabel: hasPromoLabel(rawResult),
    pricePromotion: getPricePromotion(rawResult.promotion),
    // Sales data
    productSalesType: transformSalesReleaseType(rawResult),
    description: rawResult.descriptions?.join(' '),
    // Energylabel
    energyLabelIcon: rawResult.energyLabelIconUrl?.replaceAll(urlSizeRegex, '120'),
    energyLabel: rawResult.energyLabelUrl,
    energyClassRange: rawResult.energyLabelClassRange,
    energyDatasheet: rawResult.energyLabelDataSheet,
    // Home Connect
    isHomeConnectableUrl: !!rawResult.highlights?.find(e => e === 'Home Connect')
  });
}

/**
 * Transforms vibki/service response.
 *
 * @param rawResult - This is the raw response form the API.
 * @param result - This is the Empathy result handle by the app.
 * @param request - The search request.
 *
 * @returns A new object which can be handled by XComponents.
 */
function transformENumberFeedResponse(
  rawResult: EmpathyEnumberResult,
  result: Result,
  request: Dictionary
): Result {
  return Object.assign<Result, DeepPartial<Result>>(result, {
    contentType: request.contentType,
    // x-types Identifiable
    id: rawResult.variantId,
    name: rawResult.variantId,
    // x-types Result
    type: 'VIBKI',
    url:
      `${getConfigParameter('supportDetailUrl')}/` +
      `${rawResult.variantId}#/Tabs=section-spareparts/`,
    images: [rawResult.imageUrl?.replaceAll(urlSizeRegex, '600')]
  });
}

/**
 * Transforms webcontent response.
 *
 * @param rawResult - This is the raw response form the API.
 * @param result - This is the Empathy result handle by the app.
 * @param request - The search request.
 *
 * @returns A new object which can be handled by XComponents.
 */
function transformContentFeedResponse(
  rawResult: EmpathyContentResult,
  result: Result,
  request: Dictionary
): Result {
  return Object.assign<Result, DeepPartial<Result>>(result, {
    contentType: request.contentType,
    // x-types Identifiable
    id: rawResult.id,
    name: rawResult.title,
    // x-types Result
    url: rawResult.url,
    //type: 'CONTENT',
    description: rawResult.description,
    documentType: (rawResult.documentType as DocumentType) ?? 'other'
  });
}

/**
 * Identifies PDP URL.
 *
 * @param rawResult - The search result to interprete.
 * @returns PDP url, including seoName or original absolute sparepart URL.
 *
 * @internal
 */
function getUrl(rawResult: EmpathyProductResult): string | undefined {
  const baseUrl = getConfigParameter('baseUrl');
  const seoName = getConfigParameter('seoName');
  if (!rawResult.detailUrls[0]?.includes(baseUrl)) {
    return `${baseUrl}${seoName}${rawResult.detailUrls[0]}`;
  }
  return rawResult.detailUrls[0] ?? `${baseUrl}${seoName}`;
}

/**
 * Identifies if 'PROMO' marketing flag is available.
 *
 * @param rawResult - The search result to interprete.
 * @returns True, if 'PROMO' label should be displayed.
 *
 * @internal
 */
function hasPromoLabel(rawResult: EmpathyProductResult): boolean | undefined {
  return !!rawResult.promotion?.title;
}

/**
 * Configure the result name of the result.
 *
 * @param rawResult - The raw Result.
 * @returns The name of the result.
 *
 * @internal
 *
 */
function getName(rawResult: EmpathyProductResult): string {
  if (rawResult.productHeaders) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const serie = rawResult.productHeaders.header1;
    const name = rawResult.productHeaders.header2;
    const info = rawResult.productHeaders.header3;
    const feature1 = rawResult.productHeaders.header4;
    const feature2 = rawResult.productHeaders.header5;
    const combinedName = serie && name ? [`${serie} ${name}`.trim()] : [serie, name];
    const combinedFeatures =
      feature1 && feature2 ? [`${feature1} ${feature2}`.trim()] : [feature1, feature2];
    return [...combinedName, info, ...combinedFeatures].filter(Boolean).join('<br/>');
  }
  return rawResult.code;
}

/**
 * Return the Result price objcet from raw result.
 *
 * @param rawResult - The  raw result comming from API response.
 * @returns The price object of the result.
 *
 * @internal
 *
 */
function getPrice(rawResult: EmpathyProductResult): ResultPrice {
  return {
    value: rawResult.price?.price ?? 0,
    originalValue: rawResult.price?.originalPrice ?? 0,
    hasDiscount: !!rawResult.price?.originalPrice
  };
}

/**
 * Return the result promotion.
 *
 * @param promotion - An array with the promotions.
 * @returns The promotion.
 *
 * @internal
 *
 */
function getPricePromotion({ title, text }: { title?: string; text?: string } = {}): string {
  return [title, text].join('<br/>');
}

/**
 * Returns ProductSalesType based on start and end sales.
 *
 * @param product - The product.
 * @returns ProductSalesType.
 *
 * @internal
 *
 */
function transformSalesReleaseType(product: EmpathyProductResult): ProductSalesType {
  const daysInMilliSeconds = 24 * 60 * 60 * 1000;
  // - BOSCH ES defaults to '90'
  // - BOSCH ES ignores presalse info
  // - SIEMENS NL defaults to '30'
  // - SIEMENS NL show presales info => BRAND_NEW
  const brand: string = getConfigParameter('brand') ?? 'siemens';
  const newOffsetInDays = brand.toLocaleLowerCase() === 'siemens' ? 30 : 90;
  const considerBrandNew = brand.toLocaleLowerCase() === 'siemens';
  if (!product.release?.start) {
    return;
  }
  const startSalesOffset = new Date(product.release?.start).getTime();
  if (considerBrandNew && Date.now() < startSalesOffset) {
    return 'BRAND_NEW';
  } else if (Date.now() - startSalesOffset < newOffsetInDays * daysInMilliSeconds) {
    return 'NEW';
  }
}

/**
 * Resolves value from snippetConfig.
 *
 * @param key - Parameter key.
 * @returns Parameter value.
 *
 * @internal
 *
 */
function getConfigParameter(key: string): string {
  const snippetConfig = typeof window.initX === 'function' ? window.initX() : window.initX;
  return snippetConfig ? snippetConfig[key] : undefined;
}
