<template>
  <BaseResultLink
    class="
      x-list x-list--vertical x-list--gap-04
      x-border-width--01
      x-border-color--neutral-70
      x-border-radius--02
      x-padding--06
    "
    :result="result"
  >
    <div class="x-list x-list--horizontal x-list--gap-04">
      <DownloadIcon
        v-if="result.documentType === 'ium'"
        class="x-icon--l x-font-color--lead"
        :style="{ 'stroke-width': '1.5px' }"
      />
      <InfoIcon
        v-else-if="result.documentType === 'other'"
        class="x-icon--l x-font-color--lead"
        :style="{ 'stroke-width': '1.5px' }"
      />
      <FaqIcon v-else class="x-icon--l x-font-color--lead" :style="{ 'stroke-width': '1.5px' }" />
      <h1
        v-html="result.name"
        class="x-list__item--expand x-title2 x-font-color--lead x-flex-auto x-line-clamp--4"
        style="word-break: break-word; font-size: 18px"
      />
    </div>
    <p class="x-margin--00 x-margin--left-07 x-margin-top x-line-clamp--6">
      {{ result.description }}
    </p>
    <p
      v-if="showDesktopOneColumnCard"
      class="x-margin--00 x-margin--left-07 x-margin-top x-line-clamp--6 x-font-color--neutral-35"
    >
      {{ result.content }}
    </p>
  </BaseResultLink>
</template>

<script lang="ts">
  import {
    BaseFallbackImage,
    BasePlaceholderImage,
    BaseResultCurrentPrice,
    BaseResultImage,
    BaseResultLink
  } from '@empathyco/x-components';
  import { Component } from 'vue-property-decorator';
  import DownloadIcon from '../download-icon.vue';
  import FaqIcon from '../faq-icon.vue';
  import InfoIcon from '../info-icon.vue';
  import BaseResult from './base-result';

  @Component({
    components: {
      DownloadIcon,
      BaseFallbackImage,
      BasePlaceholderImage,
      BaseResultCurrentPrice,
      BaseResultImage,
      BaseResultLink,
      FaqIcon,
      InfoIcon
    }
  })
  export default class ContentResult extends BaseResult {}
</script>
