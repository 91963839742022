



















import { BarCodeIcon, StaggeredFadeAndSlide } from '@empathyco/x-components';
import { IdentifierResult, IdentifierResults } from '@empathyco/x-components/identifier-results';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import IdentifierSuggestion from './identifier-suggestion.vue';

@Component({
  components: {
    BarCodeIcon,
    IdentifierResults,
    IdentifierResult,
    IdentifierSuggestion
  }
})
export default class IdentifierSuggestionsComponent extends Vue {
  public suggestionsAnimation = StaggeredFadeAndSlide;
}
