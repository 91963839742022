<template>
  <SlidingPanel>
    <SelectedFiltersList class="x-list--wrap x-list--justify-stretch x-list--gap-05">
      <template #default="{ filter }">
        <SimpleFilter
          class="
            x-tag
            x-border-width--01
            x-border-color--neutral-70
            x-background--neutral-95
            x-font-color--neutral-10
          "
          :filter="filter"
        >
          <template #label>
            <span>{{ filter.label }}</span>
            <CrossTinyIcon />
          </template>
        </SimpleFilter>
      </template>

      <template #facet-price="{ filter }">
        <NumberRangeFilter
          #label="{ filter: priceFilter }"
          class="
            x-tag
            x-border-width--01
            x-border-color--neutral-70
            x-background--neutral-95
            x-font-color--neutral-10
          "
          :filter="filter"
        >
          <PriceFilterLabel :filter="priceFilter" />
          <CrossTinyIcon />
        </NumberRangeFilter>
      </template>

      <template #avg-overall-rating="{ filter }">
        <NumberRangeFilter
          #label="{ filter: ratingFilter }"
          class="
            x-tag
            x-border-width--01
            x-border-color--neutral-70
            x-background--neutral-95
            x-font-color--neutral-10
          "
          :filter="filter"
        >
          <BaseRatingFilterLabel :filter="ratingFilter" />
          <CrossTinyIcon />
        </NumberRangeFilter>
      </template>
    </SelectedFiltersList>
  </SlidingPanel>
</template>

<script lang="ts">
  import { BaseRatingFilterLabel, CrossTinyIcon, SlidingPanel } from '@empathyco/x-components';
  import {
    NumberRangeFilter,
    SelectedFiltersList,
    SimpleFilter
  } from '@empathyco/x-components/facets';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import PriceFilterLabel from './custom-price-filter-label.vue';

  @Component({
    components: {
      BaseRatingFilterLabel,
      CrossTinyIcon,
      PriceFilterLabel,
      NumberRangeFilter,
      SelectedFiltersList,
      SimpleFilter,
      SlidingPanel
    }
  })
  export default class SelectedFiltersComponent extends Vue {}
</script>
