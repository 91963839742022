


















import { CrossTinyIcon, StaggeredFadeAndSlide } from '@empathyco/x-components';
import { HistoryQueries } from '@empathyco/x-components/history-queries';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    CrossTinyIcon,
    HistoryQueries
  }
})
export default class CustomHistoryQueries extends Vue {
  public suggestionsAnimation = StaggeredFadeAndSlide;
}
