





















































































import {
  BaseHeaderTogglePanel,
  BaseRatingFilterLabel,
  CheckboxCardSelectedIcon,
  CheckboxCardUnselectedIcon,
  StaggeredFadeAndSlide
} from '@empathyco/x-components';
import {
  Facets,
  FiltersList,
  FiltersSearch,
  HierarchicalFilter,
  NumberRangeFilter,
  SelectedFilters,
  SimpleFilter,
  SlicedFilters
} from '@empathyco/x-components/facets';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import CustomPriceFilterLabel from './custom-price-filter-label.vue';
import Facet from './facet.vue';

@Component({
  components: {
    BaseHeaderTogglePanel,
    BaseRatingFilterLabel,
    CheckboxCardUnselectedIcon,
    CheckboxCardSelectedIcon,
    CustomPriceFilterLabel,
    Facet,
    Facets,
    FiltersList,
    FiltersSearch,
    HierarchicalFilter,
    NumberRangeFilter,
    SelectedFilters,
    SimpleFilter,
    SlicedFilters,
    StaggeredFadeAndSlide
  }
})
export default class CustomFacets extends Vue {
  protected staggeredFadeAndSlide = StaggeredFadeAndSlide;
}
