
























import { ClearFilters } from '@empathyco/x-components/facets';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import SelectedFilters from '../facets/selected-filters.vue';

@Component({
  components: {
    ClearFilters,
    SelectedFilters
  }
})
export default class DesktopSelectedFilters extends Vue {}
