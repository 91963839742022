<template>
  <RenderlessExtraParams #default="{ value, updateValue }" name="contentType">
    <ul
      class="x-content-types-selector x-option-list x-option-list--bottom"
      :style="{ alignItems: 'flex-end', overflowX: $x.device === 'mobile' ? 'scroll' : undefined }"
    >
      <li
        v-for="type in contentTypes"
        :key="type"
        class="x-content-types-selector__item x-option-list__item"
        :class="{
          'x-option-list__item--is-selected': type === selectedContentType
        }"
      >
        <button
          @click="updateValue(type)"
          class="
            x-button
            x-content-types-selector__type
            x-list x-list--justify-center x-list--align-center
            x-padding--03
          "
          :class="
            $x.device === 'mobile'
              ? 'x-list--vertical x-padding--bottom-03 x-uppercase'
              : 'x-list--horizontal x-list--gap-03'
          "
        >
          <span>
            {{ $t(`contentTypes.${type}`) }}
          </span>
          <span
            class="x-content-types-selector__type-results"
            :class="
              $x.device === 'mobile'
                ? [
                    'x-line-height--tight x-font-size--03',
                    selectedContentType === type
                      ? 'x-font-color--neutral-35'
                      : 'x-font-color--neutral-70'
                  ]
                : ''
            "
          >
            ({{ results[type].totalResults }})
          </span>
        </button>
      </li>
    </ul>
  </RenderlessExtraParams>
</template>

<script lang="ts">
  import { Getter, State, xComponentMixin } from '@empathyco/x-components';
  import { RenderlessExtraParams } from '@empathyco/x-components/extra-params';
  import { Component, Vue } from 'vue-property-decorator';
  import { ContentType, ContentTypeSearchState } from '../store';
  import { contentTypesXModule } from '../x-module';

  @Component({
    mixins: [xComponentMixin(contentTypesXModule)],
    components: {
      RenderlessExtraParams
    }
  })
  export default class ContentTypesSelector extends Vue {
    @State('contentTypes', 'contentTypes')
    public contentTypes!: ContentType[];

    @Getter('contentTypes', 'activeContentType')
    public selectedContentType!: ContentType;

    @State('contentTypes', 'results')
    public results!: Record<ContentType, ContentTypeSearchState>;
  }
</script>

<style lang="scss">
  .x-option-list__item--is-selected .x-content-types-selector__type {
    letter-spacing: -0.4px;
  }
</style>
