<template>
  <BaseHeaderTogglePanel
    class="x-list x-facet"
    :class="
      $x.device === 'mobile'
        ? [
            'x-border-width--00',
            'x-border-width--top-01',
            'x-border-color--neutral-70',
            'x-padding--left-05',
            'x-padding--right-05'
          ]
        : []
    "
    :startCollapsed="true"
    :animation="collapseFromTop"
  >
    <template #header-content="{ open }">
      <span class="x-title3 x-flex-auto x-text--bold">
        {{ $t(`facets.${facet.label}`) }}
      </span>
      <FacetSelectedFilters :selectedFilters="selectedFilters" />
      <ChevronUpIcon v-if="open" class="x-margin--left-00 x-padding--top-02" />
      <ChevronDownIcon v-else class="x-margin--left-00 x-padding--top-02" />
    </template>
    <slot />
  </BaseHeaderTogglePanel>
</template>

<script lang="ts">
  import {
    animateScale,
    BaseHeaderTogglePanel,
    ChevronDownIcon,
    ChevronUpIcon
  } from '@empathyco/x-components';
  import { Facet, Filter } from '@empathyco/x-types';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import FacetSelectedFilters from './facet-selected-filters.vue';

  @Component({
    components: {
      BaseHeaderTogglePanel,
      ChevronDownIcon,
      ChevronUpIcon,
      FacetSelectedFilters
    }
  })
  export default class FacetComponent extends Vue {
    public collapseFromTop = animateScale();

    @Prop()
    public facet!: Facet;

    @Prop()
    public selectedFilters!: Filter[];
  }
</script>
