<template>
  <IdentifierResults #default="{ identifierResult }" :animation="suggestionsAnimation">
    <IdentifierSuggestion :identifierResult="identifierResult" class="x-suggestion">
      <BarCodeIcon />
      <IdentifierResult class="x-text--bold" :result="identifierResult" />
      <span>-</span>
      <template v-if="identifierResult.type === 'BVIB'">
        <span>{{ $t('identifierResults.bvib') }}</span>
        <span>-</span>
      </template>
      <span
        v-html="identifierResult.name"
        class="x-text--bold"
        :class="{ 'x-ellipsis': $x.device === 'desktop' }"
      />
    </IdentifierSuggestion>
  </IdentifierResults>
</template>

<script lang="ts">
  import { BarCodeIcon, StaggeredFadeAndSlide } from '@empathyco/x-components';
  import { IdentifierResult, IdentifierResults } from '@empathyco/x-components/identifier-results';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import IdentifierSuggestion from './identifier-suggestion.vue';

  @Component({
    components: {
      BarCodeIcon,
      IdentifierResults,
      IdentifierResult,
      IdentifierSuggestion
    }
  })
  export default class IdentifierSuggestionsComponent extends Vue {
    public suggestionsAnimation = StaggeredFadeAndSlide;
  }
</script>

<style lang="scss" scoped>
  ::v-deep br {
    content: '';
    &:after {
      content: ' ';
    }
  }
</style>
