import { createStoreEmitters } from '@empathyco/x-components';
import { contentTypesXStoreModule } from './module';

/**
 * {@link StoreEmitters} For the contentTypes module.
 *
 * @internal
 */
export const contentTypesEmitters = createStoreEmitters(contentTypesXStoreModule, {
  ContentTypesSearchRequestChanged: (_state, getters) => getters.request
});
