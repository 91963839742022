









































import {
  BaseFallbackImage,
  BasePlaceholderImage,
  BaseResultCurrentPrice,
  BaseResultImage,
  BaseResultLink
} from '@empathyco/x-components';
import { Component } from 'vue-property-decorator';
import DownloadIcon from '../download-icon.vue';
import FaqIcon from '../faq-icon.vue';
import InfoIcon from '../info-icon.vue';
import BaseResult from './base-result';

@Component({
  components: {
    DownloadIcon,
    BaseFallbackImage,
    BasePlaceholderImage,
    BaseResultCurrentPrice,
    BaseResultImage,
    BaseResultLink,
    FaqIcon,
    InfoIcon
  }
})
export default class ContentResult extends BaseResult {}
