<template>
  <PartialResultsList #default="{ partialResult }">
    <section
      class="
        x-list x-list--vertical x-list--gap-08
        x-border-width--bottom-01
        x-border-color--neutral-70
        x-padding--top-08 x-padding--bottom-15
      "
    >
      <header
        class="x-content-types-carousel__header x-list x-list--horizontal x-list--align-start"
      >
        <h2 class="x-content-types-carousel__title x-title1 x-flex-1">{{ partialResult.query }}</h2>
        <PartialQueryButton
          class="x-button x-button--tertiary x-button--animated x-margin--left-auto"
          :query="partialResult.query"
        >
          <template>{{ $t('viewAll') }}</template>
        </PartialQueryButton>
      </header>

      <SlidingPanel
        class="x-content-types-carousel__sliding-panel"
        buttonClass="x-border-radius--00 x-padding--05 x-padding--top-09 x-padding--bottom-09"
        :resetOnContentChange="false"
        :showButtons="$x.device === 'desktop'"
      >
        <template #sliding-panel-left-button>
          <ChevronLeftIcon />
        </template>
        <div class="x-list x-list--horizontal x-list--gap-08 x-list--align-stretch">
          <ProductResult
            v-for="item in partialResult.results"
            :key="item.id"
            class="x-partial-result__result"
            :result="item"
          />
        </div>
        <template #sliding-panel-right-button>
          <ChevronRightIcon />
        </template>
      </SlidingPanel>
    </section>
  </PartialResultsList>
</template>

<script lang="ts">
  import { ChevronLeftIcon, ChevronRightIcon, SlidingPanel } from '@empathyco/x-components';
  import { PartialResultsList, PartialQueryButton } from '@empathyco/x-components/search';
  import { Component, Vue } from 'vue-property-decorator';
  import ProductResult from '../result/product-result.vue';

  @Component({
    components: {
      ChevronLeftIcon,
      ChevronRightIcon,
      PartialResultsList,
      PartialQueryButton,
      ProductResult,
      SlidingPanel
    }
  })
  export default class CustomPartialResults extends Vue {}
</script>

<style lang="scss" scoped>
  .x-desktop {
    .x-partial-result__result {
      min-width: var(--x-size-min-width-grid-item);
      width: calc((100vw - var(--x-size-margin-layout)) / 5);
    }
  }

  .x-mobile {
    .x-partial-result__result {
      width: 50vw;
      box-sizing: border-box;
    }
  }
</style>
