import {
  createWiring,
  namespacedDebounce,
  namespacedWireCommit,
  namespacedWireDispatch,
  namespacedWireDispatchWithoutPayload
} from '@empathyco/x-components';

const wireCommit = namespacedWireCommit('categories');
const wireDispatch = namespacedWireDispatch('categories');
const wireDispatchWithoutPayload = namespacedWireDispatchWithoutPayload('categories');

const moduleDebounce = namespacedDebounce('categories');

const setCategoriesQueryWire = wireCommit('setQuery');
const clearCategoriesQueryWire = wireCommit('setQuery', '');
const setCategoriesExtraParamsWire = wireCommit('setParams');
const cancelFetchAndSaveCategoriesWire = wireDispatchWithoutPayload('cancelFetchAndSaveCategories');
const fetchAndSaveCategoriesWire = wireDispatch('fetchAndSaveCategories');
const setUrlParamsWire = wireDispatch('setUrlParams');

/**
 * Wiring configuration for the {@link CategoriesXModule | categories module}.
 *
 * @internal
 */
export const categoriesWiring = createWiring({
  UserIsTypingAQuery: {
    setCategoriesQueryWire: moduleDebounce(
      setCategoriesQueryWire,
      ({ state }) => state.config.debounceInMs,
      {
        cancelOn: 'UserAcceptedAQuery'
      }
    )
  },
  UserAcceptedAQuery: {
    setCategoriesQueryWire
  },
  UserClearedQuery: {
    clearCategoriesQueryWire,
    cancelFetchAndSaveCategoriesWire
  },
  CategoriesRequestChanged: {
    fetchAndSaveCategoriesWire
  },
  ExtraParamsChanged: {
    setCategoriesExtraParamsWire
  },
  ParamsLoadedFromUrl: {
    setUrlParamsWire
  }
});
