import { CategoriesXStoreModule } from '../types';

/**
 * Default implementation for the {@link CategoriesGetters.request} getter.
 *
 * @param state - Current {@link https://vuex.vuejs.org/guide/state.html | state} of the categories
 * module.
 *
 * @returns The categories request to fetch data from the API.
 *
 * @public
 */
export const request: CategoriesXStoreModule['getters']['request'] = ({ query, config }) => {
  const snippetConfig = typeof window.initX === 'function' ? window.initX() : window.initX;
  const targetGroup = snippetConfig?.targetGroup as string;
  const catalogType = snippetConfig?.catalogType as string;
  const lang = snippetConfig?.lang as string;
  const country = snippetConfig?.country as string;
  const locale = `${lang}-${country}`;

  return query.trim()
    ? {
        query,
        lang: lang,
        locale: locale,
        targetGroup: targetGroup,
        catalogType: catalogType ?? 'SHOP',
        rows: config.maxItemsToRequest,
        start: 0,
        internal: false
      }
    : null;
};
