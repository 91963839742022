

























import {
  BaseFallbackImage,
  BasePlaceholderImage,
  BaseResultCurrentPrice,
  BaseResultImage,
  BaseResultLink,
  CrossFade
} from '@empathyco/x-components';
import { Component } from 'vue-property-decorator';
import BaseResult from './base-result';

@Component({
  components: {
    BaseFallbackImage,
    BasePlaceholderImage,
    BaseResultCurrentPrice,
    BaseResultImage,
    BaseResultLink,
    CrossFade
  }
})
export default class SuggestionResult extends BaseResult {}
