















































import { ChevronLeftIcon, ChevronRightIcon, SlidingPanel } from '@empathyco/x-components';
import { PartialResultsList, PartialQueryButton } from '@empathyco/x-components/search';
import { Component, Vue } from 'vue-property-decorator';
import ProductResult from '../result/product-result.vue';

@Component({
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    PartialResultsList,
    PartialQueryButton,
    ProductResult,
    SlidingPanel
  }
})
export default class CustomPartialResults extends Vue {}
