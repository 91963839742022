<template>
  <MainScrollItem :item="result" tag="article" class="x-list x-list--vertical x-list--gap-04">
    <div
      class="
        x-list x-list--horizontal x-list--justify-center
        x-relative
        x-padding--left-03 x-padding--right-03
      "
    >
      <div class="x-list__item--expand">
        <slot name="image" />
      </div>
      <div
        class="x-absolute x-flex-auto x-list x-list--vertical x-list--gap-02"
        style="width: 100%"
      >
        <slot name="labels"></slot>
        <div
          v-if="result.isHomeConnectableUrl"
          class="x-list x-list--vertical x-list--gap-02"
          style="width: 58px"
        >
          <slot name="flex-campaign" />
        </div>
      </div>
    </div>

    <div class="x-flex-1 x-list x-list--vertical x-list--gap-03">
      <slot name="name" />
      <slot name="id" />
      <slot name="rating" />
    </div>

    <div>
      <slot name="energy" />
    </div>

    <div class="x-list x-list--vertical">
      <slot name="original-price" />
      <slot name="price" />
      <slot name="promotion" />
    </div>
    <slot name="stock" />
    <slot />
    <div class="x-list x-list--vertical x-list--gap-03 x-margin--top-auto">
      <slot name="add-to-cart" />
    </div>
  </MainScrollItem>
</template>

<script lang="ts">
  import { MainScrollItem } from '@empathyco/x-components/scroll';
  import { Result } from '@empathyco/x-types';
  import Vue from 'vue';
  import { Component, Prop } from 'vue-property-decorator';

  @Component({
    components: { MainScrollItem }
  })
  export default class DesktopResultLayout extends Vue {
    @Prop()
    public result!: Result;
  }
</script>
