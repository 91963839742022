<template>
  <CollapseFromTop>
    <div
      v-if="value"
      ref="popup"
      @click.stop
      :style="`--x-popup-position: ${xPosition}px`"
      class="
        x-popup x-absolute x-list x-list--horizontal x-list--align-start
        x-background--neutral-100
        x-padding--04
        x-border-width--01
        x-border-color--neutral-10
      "
      style="transform-origin: bottom center"
    >
      <div class="x-popup__content x-flex-1" style="min-width: 0">
        <slot />
      </div>
      <span class="x-popup__arrow" />
      <button
        @click="closePopup"
        class="x-popup__close x-button x-button--ghost x-padding--00 x-margin--left-auto"
        style="min-height: auto"
      >
        <CrossIcon />
      </button>
      <GlobalEvents @click="closePopup" @resize="closePopup" target="window" />
    </div>
  </CollapseFromTop>
</template>

<script lang="ts">
  import { animateScale, CrossIcon, WireMetadata, XOn } from '@empathyco/x-components';
  import Vue from 'vue';
  import GlobalEvents from 'vue-global-events';
  import { Component, Prop, Watch } from 'vue-property-decorator';

  @Component({
    components: {
      CollapseFromTop: animateScale(),
      CrossIcon,
      GlobalEvents
    }
  })
  export default class Popup extends Vue {
    @Prop()
    protected value!: false;
    protected xPosition = 0;

    closePopup(): void {
      this.$emit('input', false);
    }

    @Watch('value')
    protected async calculatePopupPosition(open: boolean): Promise<void> {
      if (open) {
        this.xPosition = 0;
        await this.$nextTick();
        const { x, width } = (this.$refs.popup as HTMLElement).getBoundingClientRect();
        const screenPadding = 18;
        if (x < screenPadding) {
          this.xPosition = screenPadding - x;
        } else if (x + width > document.body.clientWidth - screenPadding) {
          this.xPosition = document.body.clientWidth - x - width - screenPadding;
        }
      }
    }

    @Watch('value')
    protected emitEvent(open: boolean): void {
      if (open) {
        this.$x.emit('UserOpenAPopup', undefined, { target: this.$el as HTMLElement });
      }
    }

    @XOn('UserOpenAPopup')
    closeOnOtherCampaignOpen(_: undefined, { target }: WireMetadata): void {
      if (target !== (this.$el as HTMLElement)) {
        this.closePopup();
      }
    }
  }
</script>

<style scoped lang="scss">
  .x-popup {
    bottom: calc(100% + 15px);
    z-index: 1000;
    left: var(--x-popup-position, 0);

    &__arrow {
      top: 100%;
      position: absolute;
      transform: translateX(calc(var(--x-popup-position, 0) * -1));

      &:before,
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 30px;
        height: 30px;
        border: solid 15px transparent;
        box-sizing: border-box;
        border-top: solid 15px var(--x-color-base-neutral-10);
      }

      &:after {
        border-top: solid 15px var(--x-color-base-neutral-100);
        top: -1px;
      }
    }
  }
</style>
