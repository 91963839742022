<template>
  <section
    class="
      x-content-types-carousel x-list x-list--vertical x-list--gap-08
      x-border-width--bottom-01 x-border-width--top-01
      x-border-color--neutral-70
      x-padding--top-08 x-padding--bottom-15
    "
  >
    <header class="x-content-types-carousel__header x-list x-list--horizontal x-list--align-start">
      <h2 class="x-content-types-carousel__title x-title1 x-flex-1">
        {{ $t(`contentTypes.${item.contentType}`) }}
      </h2>
      <RenderlessExtraParams #default="{ updateValue }" name="contentType">
        <button
          @click="updateValue(item.contentType)"
          class="x-button x-button--tertiary x-button--animated x-margin--left-auto"
        >
          {{ $t('viewAll') }}
        </button>
      </RenderlessExtraParams>
    </header>
    <div v-if="item.spellcheckedQuery" class="x-message">
      <p>
        <span v-html="$t('spellcheck.message', { query: $x.query.search })" class="x-text" />
        <span class="x-padding--01 x-font-color--lead">{{ item.spellcheckedQuery }}</span>
      </p>
    </div>
    <SlidingPanel
      class="x-content-types-carousel__sliding-panel"
      buttonClass="x-border-radius--00 x-padding--05 x-padding--top-09 x-padding--bottom-09"
      :resetOnContentChange="false"
      :showButtons="$x.device === 'desktop'"
    >
      <template #sliding-panel-left-button>
        <ChevronLeftIcon />
      </template>
      <div class="x-list x-list--horizontal x-list--gap-08">
        <template v-for="item in item.results">
          <SimplifiedProductResult
            v-if="
              item.contentType === 'products' ||
              item.contentType === 'service' ||
              item.contentType === 'spareparts'
            "
            :key="item.id"
            class="x-content-types-carousel__result"
            :result="item"
            :withinCarousel="true"
          />
          <ContentResult
            v-else
            :key="item.id"
            class="x-content-types-carousel__result"
            :result="item"
          />
        </template>
      </div>
      <template #sliding-panel-right-button>
        <ChevronRightIcon />
      </template>
    </SlidingPanel>
  </section>
</template>

<script lang="ts">
  import {
    ChevronLeftIcon,
    ChevronRightIcon,
    Getter,
    ItemsListInjectionMixin,
    SlidingPanel
  } from '@empathyco/x-components';
  import { RenderlessExtraParams } from '@empathyco/x-components/extra-params';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import ContentResult from '../../../components/result/content-result.vue';
  import SimplifiedProductResult from '../../../components/result/simplified-product-result.vue';
  import { ContentType } from '../store/types';
  import { ContentTypeListItem } from './types';

  @Component({
    components: {
      ChevronLeftIcon,
      ChevronRightIcon,
      ContentResult,
      SimplifiedProductResult,
      SlidingPanel,
      RenderlessExtraParams
    }
  })
  export default class ContentTypeCarousel extends ItemsListInjectionMixin {
    @Prop({ required: true })
    public item!: ContentTypeListItem;

    @Getter('contentTypes', 'activeContentType')
    public activeContentType!: ContentType;
  }
</script>

<style lang="scss" scoped>
  .x-base-grid__content-type-carousel
    + .x-base-grid__content-type-carousel
    .x-content-types-carousel {
    padding-top: 0 !important;
    border-top-width: 0 !important;
  }

  .x-desktop {
    .x-content-types-carousel__result {
      min-width: var(--x-size-min-width-grid-item);
      width: calc((100vw - var(--x-size-margin-layout)) / 5);
    }
  }

  .x-mobile {
    .x-content-types-carousel {
      &__result {
        width: 50vw;
        box-sizing: border-box;
      }
    }
  }
</style>
