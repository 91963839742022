

















































































































































































































import {
  BaseEventButton,
  BaseFallbackImage,
  BasePlaceholderImage,
  BaseRating,
  BaseResultRating,
  BaseResultCurrentPrice,
  BaseResultPreviousPrice,
  BaseResultImage,
  BaseResultLink,
  CartIcon
} from '@empathyco/x-components';
import { Component } from 'vue-property-decorator';
import BaseResult from './base-result';
import CustomResultAddToCart from './custom-result-add-to-cart.vue';
import DesktopOneColumnResultLayout from './desktop-one-column-result-layout.vue';
import DesktopResultLayout from './desktop-result-layout.vue';
import EnergyLabel from './energy-label.vue';
import MobileResultLayout from './mobile-result-layout.vue';

@Component({
  components: {
    BaseEventButton,
    BaseFallbackImage,
    BasePlaceholderImage,
    BaseRating,
    BaseResultRating,
    BaseResultCurrentPrice,
    BaseResultPreviousPrice,
    BaseResultImage,
    BaseResultLink,
    CartIcon,
    CustomResultAddToCart,
    DesktopOneColumnResultLayout,
    DesktopResultLayout,
    EnergyLabel,
    MobileResultLayout
  }
})
export default class ProductResult extends BaseResult {
  private initX: any = window.initX;
  private onlineDealerLocatorUrl: string = this.initX['onlineDealerLocatorUrl'];

  protected findLocalRetailerEvent = { UserClickedResultFindLocalRetailer: this.result };

  protected redirectToFindRetailer(): void {
    const url = `${this.onlineDealerLocatorUrl}?product=${this.result.id
      ?.toString()
      .toUpperCase()}`;
    window.location.assign(url);
  }

  protected toProductDetailsEvent = { UserClickedResultProductDetails: this.result };
  protected redirectToProductDetails(): void {
    window.location.assign(this.result.url);
  }
}
