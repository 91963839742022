






















































import { BaseIdModalClose, CrossIcon } from '@empathyco/x-components';
import { ClearFilters } from '@empathyco/x-components/facets';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import CustomFacets from '../facets/custom-facets.vue';
import SelectedFilters from '../facets/selected-filters.vue';
import MobileSort from './mobile-sort.vue';

@Component({
  components: {
    BaseIdModalClose,
    ClearFilters,
    CrossIcon,
    CustomFacets,
    MobileSort,
    SelectedFilters
  }
})
export default class MobileAside extends Vue {}
