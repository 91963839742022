<template>
  <BaseResultLink class="x-row x-row--gap-04" :result="result">
    <BaseResultImage
      class="x-row__item x-row__item--span-4 x-picture--fixed-ratio"
      :animation="imageAnimation"
      :result="result"
    >
      <template #placeholder>
        <BasePlaceholderImage />
      </template>
      <template #fallback>
        <BaseFallbackImage />
      </template>
    </BaseResultImage>
    <div class="x-row__item x-row__item--span-8 x-list x-list--vertical x-list--gap-03">
      <h2
        v-html="result.name"
        class="x-title2 x-font-weight--light x-margin--top-auto"
        style="font-size: 18px"
      />
      <h1 class="x-text x-text--bold x-uppercase">{{ result.id }}</h1>
    </div>
  </BaseResultLink>
</template>

<script lang="ts">
  import {
    BaseFallbackImage,
    BasePlaceholderImage,
    BaseResultCurrentPrice,
    BaseResultImage,
    BaseResultLink,
    CrossFade
  } from '@empathyco/x-components';
  import { Component } from 'vue-property-decorator';
  import BaseResult from './base-result';

  @Component({
    components: {
      BaseFallbackImage,
      BasePlaceholderImage,
      BaseResultCurrentPrice,
      BaseResultImage,
      BaseResultLink,
      CrossFade
    }
  })
  export default class SuggestionResult extends BaseResult {}
</script>
