<template>
  <Facets>
    <template #default="{ facet, selectedFilters }">
      <Facet :facet="facet" :selectedFilters="selectedFilters">
        <SlicedFilters :max="6" :filters="facet.filters">
          <FiltersList
            #default="{ filter }"
            class="x-list--gap-03"
            :animation="staggeredFadeAndSlide"
          >
            <SimpleFilter #label :filter="filter">
              <CheckboxCardSelectedIcon v-if="filter.selected" />
              <CheckboxCardUnselectedIcon v-else />
              <span class="x-filter__label x-ellipsis">{{ filter.label }}</span>
              <span class="x-filter__count">({{ filter.totalResults }})</span>
            </SimpleFilter>
          </FiltersList>
          <template #show-more="{ difference }">
            {{ $t('filters.show') }} {{ difference }} {{ $t('filters.more') }}
            {{ $t('filters.title') }}
          </template>
          <template #show-less="{ difference }">
            {{ $t('filters.show') }} {{ difference }} {{ $t('filters.less') }}
            {{ $t('filters.title') }}
          </template>
        </SlicedFilters>
      </Facet>
    </template>

    <template #facet-categories="{ facet, selectedFilters }">
      <Facet :facet="facet" :selectedFilters="selectedFilters">
        <FiltersList
          #default="{ filter }"
          class="x-list--gap-03"
          :filters="facet.filters"
          :animation="staggeredFadeAndSlide"
        >
          <HierarchicalFilter :filter="filter">
            <template #label="{ filter }">
              <CheckboxCardSelectedIcon v-if="filter.selected" />
              <CheckboxCardUnselectedIcon v-else />
              <span class="x-filter__label x-ellipsis">{{ filter.label }}</span>
              <span class="x-filter__count">({{ filter.totalResults }})</span>
            </template>
          </HierarchicalFilter>
        </FiltersList>
      </Facet>
    </template>

    <template #facet-price="{ facet, selectedFilters }">
      <Facet :facet="facet" :selectedFilters="selectedFilters">
        <FiltersList
          #default="{ filter }"
          class="x-list--gap-03"
          :filters="facet.filters"
          :animation="staggeredFadeAndSlide"
        >
          <NumberRangeFilter #label :filter="filter">
            <CheckboxCardSelectedIcon v-if="filter.selected" />
            <CheckboxCardUnselectedIcon v-else />
            <CustomPriceFilterLabel :filter="filter" />
          </NumberRangeFilter>
        </FiltersList>
      </Facet>
    </template>

    <template #avg-overall-rating="{ facet, selectedFilters }">
      <Facet :facet="facet" :selectedFilters="selectedFilters">
        <FiltersList
          #default="{ filter }"
          class="x-list--gap-03"
          :filters="facet.filters"
          :animation="staggeredFadeAndSlide"
        >
          <NumberRangeFilter #label :filter="filter">
            <CheckboxCardSelectedIcon v-if="filter.selected" />
            <CheckboxCardUnselectedIcon v-else />
            <BaseRatingFilterLabel :max="5" :filter="filter" />
          </NumberRangeFilter>
        </FiltersList>
      </Facet>
    </template>
  </Facets>
</template>

<script lang="ts">
  import {
    BaseHeaderTogglePanel,
    BaseRatingFilterLabel,
    CheckboxCardSelectedIcon,
    CheckboxCardUnselectedIcon,
    StaggeredFadeAndSlide
  } from '@empathyco/x-components';
  import {
    Facets,
    FiltersList,
    FiltersSearch,
    HierarchicalFilter,
    NumberRangeFilter,
    SelectedFilters,
    SimpleFilter,
    SlicedFilters
  } from '@empathyco/x-components/facets';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import CustomPriceFilterLabel from './custom-price-filter-label.vue';
  import Facet from './facet.vue';

  @Component({
    components: {
      BaseHeaderTogglePanel,
      BaseRatingFilterLabel,
      CheckboxCardUnselectedIcon,
      CheckboxCardSelectedIcon,
      CustomPriceFilterLabel,
      Facet,
      Facets,
      FiltersList,
      FiltersSearch,
      HierarchicalFilter,
      NumberRangeFilter,
      SelectedFilters,
      SimpleFilter,
      SlicedFilters,
      StaggeredFadeAndSlide
    }
  })
  export default class CustomFacets extends Vue {
    protected staggeredFadeAndSlide = StaggeredFadeAndSlide;
  }
</script>
