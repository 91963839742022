import { gql } from 'graphql-tag';

export const sparepartsQuery = gql`
  query EmpathyPilotSparepartQuery($productId: String!) {
    product(productId: $productId) {
      id
      type
      header {
        value
        level
      }
      assets {
        images {
          category
          url
        }
      }
      salesInformation {
        startSales
        endSales
        release {
          brand
          channel
          start
          end
        }
      }
    }
    availability(productId: $productId) {
      visibility
      sellable
      status
      stockIndicator
    }
    price(productId: $productId) {
      price {
        amount
        currency
      }
    }
    promotion(productId: $productId) {
      title
      details
      price {
        price {
          amount
          currency
        }
      }
    }
  }
`;
