






import { BaseGrid, State, xComponentMixin } from '@empathyco/x-components';
import { Component, Vue } from 'vue-property-decorator';
import { Result } from '@empathyco/x-types';
import { resultSuggestionsXModule } from '../x-module';
import SuggestionResult from '../../../components/result/suggestion-result.vue';

@Component({
  mixins: [xComponentMixin(resultSuggestionsXModule)],
  components: {
    SuggestionResult,
    BaseGrid
  }
})
export default class ResultSuggestions extends Vue {
  @State('resultSuggestions', 'resultSuggestions')
  public resultSuggestions!: Result[];
}
