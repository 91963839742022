







































import { BaseIdModalClose, CrossIcon } from '@empathyco/x-components';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import CustomFacets from '../facets/custom-facets.vue';

@Component({
  components: {
    BaseIdModalClose,
    CrossIcon,
    CustomFacets
  }
})
export default class DesktopAside extends Vue {}
