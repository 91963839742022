































import { ClearSearchInput, SearchButton, SearchInput } from '@empathyco/x-components/search-box';
import { CrossIcon } from '@empathyco/x-components';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    ClearSearchInput,
    SearchButton,
    SearchInput,
    CrossIcon
  }
})
export default class SearchBox extends Vue {}
