import {
  DEPENDENCIES,
  Dictionary,
  MapRequest,
  pipeMappers,
  RequestMapper,
  RequestMapperContext,
  SearchRequest
} from '@empathyco/x-adapter';
import { Filter, Sort } from '@empathyco/x-types';
import { injectable, multiInject } from 'inversify';

export interface PlatformSearchRequest {
  catalogue?: string;
  filter: string[];
  lang: string;
  origin?: string;
  query: string;
  rows?: number;
  scope?: string;
  sort?: string;
  sortDirection?: string;
  start?: number;
  store?: string;
  warehouse?: string;
  internal: boolean;
}

/**
 * @public
 */
@injectable()
export class SearchRequestMapper implements RequestMapper<SearchRequest, PlatformSearchRequest> {
  private readonly mapFilters: MapRequest<Dictionary<Filter[]>, string[]>;
  private readonly mapSort: MapRequest<Sort | undefined, string | undefined>;

  public constructor(
    @multiInject(DEPENDENCIES.RequestMappers.Parameters.filters)
    filtersMapper: RequestMapper<Dictionary<Filter[]>, string[]>[],
    @multiInject(DEPENDENCIES.RequestMappers.Parameters.sort)
    sortMappers: RequestMapper<Sort | undefined, string | undefined>[]
  ) {
    this.mapFilters = pipeMappers(...filtersMapper);
    this.mapSort = pipeMappers(...sortMappers);
  }

  map(
    { filters = {}, sort, ...rest }: SearchRequest,
    request: PlatformSearchRequest,
    context: RequestMapperContext
  ): PlatformSearchRequest {
    let searchRequest = this.removeBSHConfigParameters(rest);
    const requestId = context.requestOptions.requestId;
    switch (requestId) {
      case 'explore':
      case 'manualsAndFAQs':
      case 'service':
        searchRequest = this.removeProductFeedParameters(searchRequest);
        break;
    }

    searchRequest = this.removeArchetypeParameters(searchRequest);

    // Mapping sort fields
    /*
     * Supported sort fields
     * "field": "productHeader1",
     * "field": "energyLabelClass",
     * "field": "avgOverallRating",
     * "field": "salesDataMargin",
     * "field": "salesDataTurnoverUnit",
     * "field": "releaseStart",
     * "field": "priceValue",
     * "field": "availabilityVisibility",
     */
    const mappedSort = sort?.replace('price', 'priceValue');

    return Object.assign<PlatformSearchRequest, Partial<PlatformSearchRequest>>(request, {
      ...searchRequest,
      filter: this.mapFilters(filters, [], context),
      sort: this.mapSort(mappedSort, '', context),
      internal: false
    });
  }

  private removeBSHConfigParameters(request: SearchRequest): SearchRequest {
    const {
      // Remove BSH config parameters from query
      brand,
      channel,
      country,
      baseUrl,
      seoName,
      mockGraphqlResponse,
      onlineDealerLocatorUrl,
      supportDetailUrl,
      categoryPageRedirectUrl,
      ...rest
    } = request;
    return rest;
  }

  private removeProductFeedParameters(request: SearchRequest): SearchRequest {
    const { targetGroup, catalogType, ...rest } = request;
    return rest;
  }

  private removeArchetypeParameters(request: SearchRequest): SearchRequest {
    const { env, scope, device, ...rest } = request;
    return rest;
  }
}
