










import { Component, Vue } from 'vue-property-decorator';
// eslint-disable-next-line max-len
import ContentTypesSelector from '../../x-modules/content-types/components/content-types-selector.vue';

@Component({
  components: {
    ContentTypesSelector
  }
})
export default class MobileToolbar extends Vue {}
