

















import { BaseAddToCart, VueCSSClasses, XOn } from '@empathyco/x-components';
import { Result } from '@empathyco/x-types';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Popup from './popup.vue';

@Component({
  inheritAttrs: false,
  components: {
    BaseAddToCart,
    Popup
  }
})
export default class CustomResultAddToCart extends Vue {
  @Prop()
  protected result!: Result;

  @Prop()
  protected cssClass!: VueCSSClasses;

  protected open = false;
  protected popupHtml = '';

  @XOn('SnippetCallbackExecuted')
  getAddToCartCallbackHtml({
    event,
    callbackReturn,
    payload
  }: {
    event: string;
    callbackReturn: unknown;
    payload: Result;
  }): void {
    if (event === 'UserClickedResultAddToCart' && payload === this.result) {
      (callbackReturn as Promise<string>).then(html => {
        this.popupHtml = html;
        this.open = true;
      });
    }
  }
}
