




















































import { Getter, State, xComponentMixin } from '@empathyco/x-components';
import { RenderlessExtraParams } from '@empathyco/x-components/extra-params';
import { Component, Vue } from 'vue-property-decorator';
import { ContentType, ContentTypeSearchState } from '../store';
import { contentTypesXModule } from '../x-module';

@Component({
  mixins: [xComponentMixin(contentTypesXModule)],
  components: {
    RenderlessExtraParams
  }
})
export default class ContentTypesSelector extends Vue {
  @State('contentTypes', 'contentTypes')
  public contentTypes!: ContentType[];

  @Getter('contentTypes', 'activeContentType')
  public selectedContentType!: ContentType;

  @State('contentTypes', 'results')
  public results!: Record<ContentType, ContentTypeSearchState>;
}
