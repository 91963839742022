import {
  DEPENDENCIES,
  EmpathyAdapterBuilder,
  EmpathyFacet,
  EmpathyFacetMapper,
  EmpathyNumberRangeFacetMapper,
  EmpathyRequestParamsMapper,
  EmpathySimpleFacetMapper,
  FetchHttpClient
} from '@empathyco/x-adapter';
import { SnippetConfig } from '@empathyco/x-components';
import { CustomHttpClient } from './custom-http-client';
import { bannerMapper } from './demo-banner-mapper';
import { HierarchicalFacetMapper } from './demo-hierarchical-mapper';
import { CustomPartialResultMapper } from './demo-partials-mapper';
import { promotedMapper } from './demo-promoted-mapper';
import { RequestFiltersMapper } from './demo-request-filters.mapper';
import { SearchRequestMapper } from './demo-request-mapper';
import { resultMapper } from './demo-result.mapper';
import { priceFilterMapper } from './demo-price-filter-mapper';
import { EmpathyEndpointsService } from './empathy-endpoints.service';

declare global {
  interface Window {
    initX?: SnippetConfig | (() => SnippetConfig);
  }
}

export const adapter = new EmpathyAdapterBuilder()
  .addMapper(resultMapper, 'results')
  .addMapper(bannerMapper, 'banners')
  .addMapper(promotedMapper, 'promoteds')
  .addMapper(priceFilterMapper, 'numberRangeFilter')
  .replaceClassRequestMapper(SearchRequestMapper)
  .setResultTrackingConfig({
    add2cart: 'tagging.add2cart',
    click: 'tagging.click',
    checkout: 'tagging.checkout'
  })
  .setFeatureConfig('search', {
    endpoint: `{env}/query/{brand}-{channel}-products/search`,
    responsePaths: {
      results: 'catalog.content',
      facets: 'catalog.facets',
      totalResults: 'catalog.numFound',
      spellcheck: 'catalog.spellchecked',
      banners: 'banner.content',
      promoteds: 'promoted.content',
      redirections: 'direct.content',
      queryTagging: 'catalog.tagging.query',
      partialResults: 'catalog.partials'
    }
  })
  .setFeatureConfig('searchById', {
    endpoint: '{env}/query/{brand}-{channel}-products/search',
    responsePaths: {
      results: 'catalog.content'
    }
  })
  .setFeatureConfig('relatedTags', {
    endpoint: '{env}.empathy.co/relatedtags/{brand}-{channel}-products',
    responsePaths: {
      relatedTags: 'data.relatedtags'
    }
  })
  .setFeatureConfig('nextQueries', {
    endpoint: '{env}.empathy.co/nextqueries/{brand}-{channel}-products',
    responsePaths: {
      nextQueries: 'data.nextqueries'
    }
  })
  .setFeatureConfig('suggestions', {
    endpoint: '{env}/query/{brand}-{channel}-products/empathize',
    responsePaths: {
      suggestions: 'topTrends.content'
    }
  })
  .setFeatureConfig('topRecommendations', {
    endpoint: '{env}/query/{brand}-{channel}-products/topclicked',
    responsePaths: {
      results: 'topclicked.content'
    }
  })
  .setFacetConfig({ modelName: 'HierarchicalFacet' }, 'facetCategories')
  .setFacetConfig({ modelName: 'NumberRangeFacet' }, 'facetPrice')
  .configureContainer(container => {
    container.unbind(DEPENDENCIES.ResponseMappers.facets);
    container.bind(DEPENDENCIES.ResponseMappers.facets).to(EmpathyFacetMapper);
    container.bind(DEPENDENCIES.ResponseMappers.facets).to(HierarchicalFacetMapper);
    container.bind(DEPENDENCIES.ResponseMappers.facets).to(EmpathyNumberRangeFacetMapper);
    container.bind(DEPENDENCIES.ResponseMappers.facets).to(EmpathySimpleFacetMapper);
    container.bind(DEPENDENCIES.RequestMappers.Parameters.filtersValue).to(RequestFiltersMapper);
    container.bind(DEPENDENCIES.requestMappers).to(EmpathyRequestParamsMapper);
    container.rebind(DEPENDENCIES.ResponseMappers.partialResults).to(CustomPartialResultMapper);
    container.rebind(DEPENDENCIES.endpointsService).to(EmpathyEndpointsService);
    container
      .rebind(DEPENDENCIES.httpClient)
      .to(FetchHttpClient)
      .whenNoAncestorIs(DEPENDENCIES.Requestors.search);
    container
      .bind(DEPENDENCIES.httpClient)
      .to(CustomHttpClient)
      .whenAnyAncestorIs(DEPENDENCIES.Requestors.search);
  })
  .onBeforeRequest(({ request }) => {
    delete request.scope;
  }, 'topRecommendations')
  .onBeforeRequest(({ request }) => {
    if (request.scope === 'none') {
      delete request.scope;
    }
  }, 'search')
  .onBeforeResponseTransformed(
    (context: {
      rawResponse: { catalog: any; enumbers: any; banner: any; direct: any; promoted: any };
    }) => {
      if (context.rawResponse.enumbers) {
        context.rawResponse.catalog = context.rawResponse.enumbers;
        context.rawResponse.banner = context.rawResponse.banner ?? { content: [] };
        context.rawResponse.direct = context.rawResponse.direct ?? { content: [] };
        context.rawResponse.promoted = context.rawResponse.promoted ?? { content: [] };
      }
      context.rawResponse.catalog.facets =
        (context.rawResponse.catalog?.facets as EmpathyFacet[])?.filter(
          (rawFacet: EmpathyFacet) =>
            !!rawFacet.values?.length &&
            !rawFacet.facet.startsWith('facet_') &&
            rawFacet.facet !== 'featuresCode' &&
            rawFacet.facet !== 'featuresName' &&
            rawFacet.facet !== 'featuresValue'
        ) ?? [];
      (context.rawResponse.catalog?.facets as EmpathyFacet[])?.forEach(element => {
        if (element.facet === 'avgOverallRating') {
          element.values.reverse();
        }
        if (element.facet === 'facetHighlights') {
          element.values = element.values.slice(0, 5);
        }
      });
    },
    'search'
  )
  .setInstance('platform')
  .build();
