<template>
  <BaseHeaderTogglePanel class="x-facet" :startCollapsed="true" :animation="collapseFromTop">
    <template #header-content="{ open }">
      <div class="x-list x-list--vertical">
        <span class="x-title3 x-font-weight--bold">{{ $t('sort.label') }}</span>
        <span class="x-font-color--lead x-margin--top-02">
          {{ $t(`sort.values.${sortValue || 'default'}`) }}
        </span>
      </div>
      <ChevronUpIcon v-if="open" />
      <ChevronDownIcon v-else />
    </template>
    <SortList
      v-if="$x.totalResults"
      #default="{ item }"
      class="x-list x-list--vertical x-option-list--bottom x-list--align-start x-list--gap-03"
      :items="sortValues"
    >
      {{ $t(`sort.values.${item || 'default'}`) }}
    </SortList>
  </BaseHeaderTogglePanel>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import { Sort } from '@empathyco/x-types';
  import { SortList } from '@empathyco/x-components/search';
  import {
    BaseHeaderTogglePanel,
    CheckTinyIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    ChevronTinyDownIcon,
    State,
    animateScale
  } from '@empathyco/x-components';

  @Component({
    components: {
      SortList,
      CheckTinyIcon,
      ChevronDownIcon,
      ChevronUpIcon,
      ChevronTinyDownIcon,
      BaseHeaderTogglePanel
    }
  })
  export default class MobileSort extends Vue {
    protected collapseFromTop = animateScale();
    public sortValues: Sort[] = ['', 'price asc', 'price desc'];

    @State('search', 'sort')
    public sortValue!: string;
  }
</script>

<style lang="scss" scoped>
  .x-facet {
    --x-size-padding-left-option-list-button-default: 0;
  }
</style>
