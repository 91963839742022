<template>
  <HistoryQueries :animation="suggestionsAnimation" :max-items-to-render="4">
    <template #suggestion-content="{ queryHTML }">
      <span class="x-icon icon icon-search"></span>
      <span v-html="queryHTML" />
    </template>

    <template #suggestion-remove-content="{ suggestion }">
      <span
        class="x-font-color--neutral-35"
        :aria-label="$t('historyQueries.removeLabel', { suggestion: suggestion.query })"
      >
        <CrossTinyIcon />
      </span>
    </template>
  </HistoryQueries>
</template>

<script lang="ts">
  import { CrossTinyIcon, StaggeredFadeAndSlide } from '@empathyco/x-components';
  import { HistoryQueries } from '@empathyco/x-components/history-queries';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';

  @Component({
    components: {
      CrossTinyIcon,
      HistoryQueries
    }
  })
  export default class CustomHistoryQueries extends Vue {
    public suggestionsAnimation = StaggeredFadeAndSlide;
  }
</script>
