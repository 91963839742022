import { DEPENDENCIES, EmpathyAdapterConfig, EndpointsService } from '@empathyco/x-adapter';
import { inject, injectable } from 'inversify';

@injectable()
export class EmpathyEndpointsService implements EndpointsService {
  private initX: any = window.initX;
  private brand: string = this.initX['brand'];
  private channel: string = this.initX['channel'];

  public constructor(@inject(DEPENDENCIES.config) private readonly config: EmpathyAdapterConfig) {}

  buildUrl(rawUrl: string): string {
    let url = rawUrl
      .replace(/{brand}/g, this.brand.toLowerCase())
      .replace(/{channel}/g, this.channel.toLocaleLowerCase());

    if (!url.startsWith('http')) {
      url = `https://${url}`;
    }

    if (url.includes('nextqueries')) {
      return url.replace(/{env}/g, 'api');
    }

    if (url.includes('relatedtags')) {
      if (this.config.env === 'test') {
        return url.replace(/{env}/g, 'beacon-api.internal.test');
      } else if (this.config.env === 'staging') {
        return url.replace(/{env}/g, 'api.staging');
      } else {
        return url.replace(/{env}/g, 'api');
      }
    }

    if (this.config.env === 'test') {
      return url.replace(/{env}/g, 'search.internal.test.empathy.co');
    } else if (this.config.env === 'staging') {
      return url.replace(/{env}/g, 'api.staging.empathy.co/search/v1');
    } else {
      return url.replace(/{env}/g, 'api.empathy.co/search/v1');
    }
  }
}
