








import { xComponentMixin } from '@empathyco/x-components';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Category } from '../store';
import { categoriesXModule } from '../x-module';

@Component({
  mixins: [xComponentMixin(categoriesXModule)]
})
export default class CategoryLink extends Vue {
  @Prop({ required: true })
  public category!: Category;
}
