
























































































































































import {
  BaseEventButton,
  BaseFallbackImage,
  BasePlaceholderImage,
  BaseRating,
  BaseResultRating,
  BaseResultCurrentPrice,
  BaseResultPreviousPrice,
  BaseResultImage,
  BaseResultLink,
  CartIcon
} from '@empathyco/x-components';
import { Component } from 'vue-property-decorator';
import BaseResult from './base-result';
import CustomResultAddToCart from './custom-result-add-to-cart.vue';
import DesktopOneColumnResultLayout from './desktop-one-column-result-layout.vue';
import DesktopResultLayout from './desktop-result-layout.vue';
import MobileResultLayout from './mobile-result-layout.vue';

@Component({
  components: {
    BaseEventButton,
    BaseFallbackImage,
    BasePlaceholderImage,
    BaseRating,
    BaseResultRating,
    BaseResultCurrentPrice,
    BaseResultPreviousPrice,
    BaseResultImage,
    BaseResultLink,
    CartIcon,
    CustomResultAddToCart,
    DesktopOneColumnResultLayout,
    DesktopResultLayout,
    MobileResultLayout
  }
})
export default class SparePartResult extends BaseResult {
  protected findLocalRetailerEvent = { UserClickedResultFindLocalRetailer: this.result };

  protected redirectToFindRetailer(): void {
    window.location.assign(
      `https://www.bosch-home.es/donde-comprar?product=${this.result.id
        ?.toString()
        .toUpperCase()}`
    );
  }
}
