<template>
  <div v-if="$x.query.search && !$x.noResults" class="x-margin--top-07">
    <span
      v-if="$x.totalResults && $x.status.search === 'success'"
      v-html="$t('totalResults.message', { totalResults: $x.totalResults, query: $x.query.search })"
      class="x-title1"
    ></span>

    <div class="x-list x-list--horizontal x-list--align-start x-margin--top-05">
      <SelectedFilters
        v-if="$x.totalResults > 0"
        class="x-list x-list--wrap x-list--gap-05 x-padding--bottom-05"
      />
      <ClearFilters
        #default="{ selectedFilters }"
        class="x-button--secondary x-margin--left-05"
        :alwaysVisible="false"
      >
        {{ $t('selectedFilters.clear', { selectedFiltersNumber: selectedFilters.length }) }}
      </ClearFilters>
    </div>
  </div>
</template>

<script lang="ts">
  import { ClearFilters } from '@empathyco/x-components/facets';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import SelectedFilters from '../facets/selected-filters.vue';

  @Component({
    components: {
      ClearFilters,
      SelectedFilters
    }
  })
  export default class DesktopSelectedFilters extends Vue {}
</script>

<style scoped>
  .x-clear-filters {
    --x-size-height-button-default: var(--x-size-base-07);
  }
</style>
