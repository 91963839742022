






















































import { Getter, map, objectFilter, State, XInject } from '@empathyco/x-components';
import { RenderlessExtraParams } from '@empathyco/x-components/extra-params';
import { Component, Vue } from 'vue-property-decorator';
import { ContentType, ContentTypeSearchState } from '../x-modules/content-types/index';
import NoResultsLinks from './no-results-links.vue';
import CustomRecommendations from './results/custom-recommendations.vue';

@Component({
  components: {
    CustomRecommendations,
    NoResultsLinks,
    RenderlessExtraParams
  }
})
export default class NoResultsMessage extends Vue {
  @XInject('noResultsAtAll')
  public noResultsAtAll!: boolean;

  @State('contentTypes', 'results')
  public contentTypeResults!: Record<ContentType, ContentTypeSearchState>;

  @Getter('contentTypes', 'activeContentType')
  public selectedContentType!: ContentType;

  protected get otherContentTypes(): Record<ContentType, number> {
    return map(
      objectFilter(
        this.contentTypeResults,
        (key, { totalResults }) => key !== this.selectedContentType && totalResults > 0
      ),
      (_key, { totalResults }) => totalResults
    );
  }
}
