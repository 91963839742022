<template>
  <div class="x-list x-list--vertical">
    <DesktopSelectedFilters v-if="$x.device === 'desktop'" />
    <CustomRedirection />
    <SpellcheckMessage
      :class="{
        'x-padding--top-03 x-padding--bottom-03 x-margin--bottom-06': $x.device === 'desktop'
      }"
    />
    <NoResultsMessage :class="{ 'x-padding--top-03': $x.device === 'desktop' }" />

    <CustomResultsList :class="{ 'x-padding--05': $x.device === 'mobile' }" />

    <CustomPartialResults :class="{ 'x-padding--05': $x.device === 'mobile' }" />
  </div>
</template>

<script lang="ts">
  import { LocationProvider } from '@empathyco/x-components';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import DesktopSelectedFilters from './desktop/desktop-selected-filters.vue';
  import NoResultsMessage from './no-results-message.vue';
  import CustomPartialResults from './results/custom-partial-results.vue';
  import CustomRedirection from './results/custom-redirection.vue';
  import CustomResultsList from './results/custom-results-list.vue';
  import SpellcheckMessage from './spellcheck-message.vue';

  @Component({
    components: {
      CustomPartialResults,
      CustomRedirection,
      DesktopSelectedFilters,
      LocationProvider,
      NoResultsMessage,
      CustomResultsList,
      SpellcheckMessage
    }
  })
  export default class Main extends Vue {}
</script>
