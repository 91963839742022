import {
  createWiring,
  namespacedDebounce,
  namespacedWireCommit,
  namespacedWireDispatch,
  namespacedWireDispatchWithoutPayload
} from '@empathyco/x-components';

const wireCommit = namespacedWireCommit('resultSuggestions');
const wireDispatch = namespacedWireDispatch('resultSuggestions');
const wireDispatchWithoutPayload = namespacedWireDispatchWithoutPayload('resultSuggestions');

const moduleDebounce = namespacedDebounce('resultSuggestions');

const setResultSuggestionsQueryWire = wireCommit('setQuery');
const clearResultSuggestionsQueryWire = wireCommit('setQuery', '');
const setResultSuggestionsExtraParamsWire = wireCommit('setParams');
const setUrlParams = wireDispatch('setUrlParams');

const cancelFetchAndSaveResultSuggestionsWire = wireDispatchWithoutPayload(
  'cancelFetchAndSaveResultSuggestions'
);
const fetchAndSaveResultSuggestionsWire = wireDispatch('fetchAndSaveResultSuggestions');

/**
 * Wiring configuration for the {@link ResultSuggestionsXModule | resultSuggestions module}.
 *
 * @internal
 */
export const resultSuggestionsWiring = createWiring({
  UserIsTypingAQuery: {
    setResultSuggestionsQueryWire: moduleDebounce(
      setResultSuggestionsQueryWire,
      ({ state }) => state.config.debounceInMs,
      {
        cancelOn: 'UserAcceptedAQuery'
      }
    )
  },
  UserAcceptedAQuery: {
    setResultSuggestionsQueryWire
  },
  UserClearedQuery: {
    clearResultSuggestionsQueryWire,
    cancelFetchAndSaveResultSuggestionsWire
  },
  ResultSuggestionsSearchRequestChanged: {
    fetchAndSaveResultSuggestionsWire
  },
  ExtraParamsChanged: {
    setResultSuggestionsExtraParamsWire
  },
  ParamsLoadedFromUrl: {
    setUrlParams
  }
});
