import { createFetchAndSaveActions } from '@empathyco/x-components';
import { CategoriesActionsContext, CategoriesRequest, Category } from '../types';

const { fetchAndSave, cancelPrevious } = createFetchAndSaveActions<
  CategoriesActionsContext,
  CategoriesRequest | null,
  Category[]
>({
  fetch({ dispatch }, request) {
    return dispatch('fetchCategories', request);
  },
  onSuccess({ commit }, categories) {
    commit('setCategories', categories);
  }
});

/**
 * Default implementation for {@link CategoriesActions.fetchAndSaveCategories} action.
 *
 * @public
 */
export const fetchAndSaveCategories = fetchAndSave;

/**
 * Default implementation for {@link CategoriesActions.cancelFetchAndSaveCategories} action.
 *
 * @public
 */
export const cancelFetchAndSaveCategories = cancelPrevious;
