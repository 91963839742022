














import { State, xComponentMixin } from '@empathyco/x-components';
import { Component, Vue } from 'vue-property-decorator';
import { Category as CategoryModel } from '../store/types';
import { categoriesXModule } from '../x-module';
import CategoryLink from './category-link.vue';

@Component({
  mixins: [xComponentMixin(categoriesXModule)],
  components: { CategoryLink }
})
export default class CategoriesList extends Vue {
  @State('categories', 'categories')
  public categories!: CategoryModel[];
}
