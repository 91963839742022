<template>
  <BasePriceFilterLabel
    :filter="filter"
    format="i €"
    :lessThan="$t('priceFilter.lessThan')"
    :fromTo="$t('priceFilter.fromTo')"
    :from="$t('priceFilter.from')"
  />
</template>

<script lang="ts">
  import { BasePriceFilterLabel } from '@empathyco/x-components';
  import { Filter } from '@empathyco/x-types';
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component({
    components: {
      BasePriceFilterLabel
    }
  })
  export default class CustomPriceFilterLabel extends Vue {
    @Prop()
    public filter!: Filter;
  }
</script>
