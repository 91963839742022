















import { BaseGrid, StaggeredFadeAndSlide } from '@empathyco/x-components';
import { Recommendations } from '@empathyco/x-components/recommendations';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import SuggestionResult from '../result/suggestion-result.vue';

@Component({
  components: {
    SuggestionResult,
    BaseGrid,
    Recommendations
  }
})
export default class CustomRecommendations extends Vue {
  protected staggeredFadeAndSlide = StaggeredFadeAndSlide;

  @Prop()
  protected maxItemsToRender!: number;

  @Prop({ default: 0 })
  protected columns!: number;
}
