<template>
  <QuerySuggestions
    #suggestion-content="{ queryHTML }"
    :animation="suggestionsAnimation"
    :max-items-to-render="4"
  >
    <span class="x-icon icon icon-search"></span>
    <span v-html="queryHTML" />
  </QuerySuggestions>
</template>

<script lang="ts">
  import { StaggeredFadeAndSlide } from '@empathyco/x-components';
  import { QuerySuggestions } from '@empathyco/x-components/query-suggestions';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';

  @Component({
    components: {
      QuerySuggestions
    }
  })
  export default class CustomQuerySuggestions extends Vue {
    public suggestionsAnimation = StaggeredFadeAndSlide;
  }
</script>
