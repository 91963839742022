import {
  DEPENDENCIES,
  EmpathyPartialResult,
  EmpathyResult,
  MapFn,
  pipeMappers,
  ResponseMapper,
  ResponseMapperContext
} from '@empathyco/x-adapter';
import { PartialResult, Result } from '@empathyco/x-types';
import { injectable, multiInject } from 'inversify';

interface CustomPartialResult {
  content: EmpathyResult[];
  term: string;
}

declare module '@empathyco/x-adapter' {
  interface EmpathyPartialResult extends CustomPartialResult {}
}

@injectable()
export class CustomPartialResultMapper
  implements ResponseMapper<EmpathyPartialResult, PartialResult>
{
  private readonly mapResult: MapFn<EmpathyResult, Result>;

  public constructor(
    @multiInject(DEPENDENCIES.ResponseMappers.results)
    resultMappers: ResponseMapper<EmpathyResult, Result>[]
  ) {
    this.mapResult = pipeMappers(...resultMappers);
  }

  map(
    rawPartialResult: EmpathyPartialResult,
    partialResult: PartialResult,
    context: ResponseMapperContext
  ): PartialResult {
    return Object.assign(partialResult, {
      query: rawPartialResult.term,
      totalResults: rawPartialResult.numFound,
      results: rawPartialResult.content.map(rawResult =>
        this.mapResult(rawResult, {} as Result, context)
      )
    });
  }
}
