









import { StaggeredFadeAndSlide } from '@empathyco/x-components';
import { NextQueries } from '@empathyco/x-components/next-queries';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import LightbulbOffIcon from '../lightbulb-off-icon.vue';

@Component({
  components: {
    LightbulbOffIcon,
    NextQueries
  }
})
export default class CustomNextQueries extends Vue {
  public suggestionsAnimation = StaggeredFadeAndSlide;
}
