import { filter, InstallXOptions, namespacedWireDispatch, Wire } from '@empathyco/x-components';
import { Result, TaggingInfo } from '@empathyco/x-types';
import App from '../App.vue';
import { I18n } from '../i18n/i18n.plugin';
import * as messages from '../i18n/messages';
import store from '../store';
import { adapter } from '../adapter/adapter';
import { relatedTagsInstallOptions } from './related-tags.options';

// TODO: remove this ñapa when https://searchbroker.atlassian.net/browse/EX-5897 done
const trackResultClickedWire: Wire<Result> = filter(
  namespacedWireDispatch('tagging')('track', ({ eventPayload }): TaggingInfo => {
    const result = eventPayload as Result;
    return result.tagging.click!;
  }),
  ({ eventPayload: { tagging } }) => !!tagging?.click
);
const taggingOptions = { wiring: { UserClickedResultAddToCart: { trackResultClickedWire } } };

export const installXOptions: InstallXOptions = {
  adapter,
  store,
  app: App,
  async installExtraPlugins({ vue, snippet }) {
    adapter.setConfig({ env: snippet.env ?? 'live' });

    const i18n = await I18n.create({
      locale: snippet.lang,
      device: snippet.device ?? 'mobile',
      fallbackLocale: 'en',
      messages
    });
    vue.use(i18n);
    vue.prototype.$setLocale = i18n.setLocale.bind(i18n);
    vue.prototype.$setLocaleDevice = i18n.setDevice.bind(i18n);

    return {
      i18n: i18n.vueI18n
    };
  },
  xModules: {
    identifierResults: {
      config: { identifierDetectionRegexp: '^\\S*\\d\\S*$' }
    },
    relatedTags: relatedTagsInstallOptions,
    tagging: taggingOptions
  }
};
