<template>
  <div
    class="x-input-group"
    :class="
      $x.device === 'mobile'
        ? 'x-input-group--card'
        : 'x-input-group--line x-background--transparent'
    "
  >
    <SearchInput
      :instant="false"
      :placeholder="$t('searchBox.placeholder')"
      :autofocus="!$x.query.searchBox"
    />
    <ClearSearchInput v-if="$x.query.searchBox">
      <CrossIcon v-if="$x.device === 'mobile'" />
      <span v-else class="x-font-color--neutral-35">{{ $t('searchBox.clear') }}</span>
    </ClearSearchInput>
    <SearchButton
      class="x-input-group__action"
      :class="{ 'x-button--ghost': $x.device === 'desktop' }"
    >
      <!-- DON'T REMOVE: Classes `icon` and `icon-search` are from BSH. -->
      <span
        class="x-icon x-icon--l icon icon-search"
        :class="{ 'x-font-color--lead': $x.device === 'desktop' }"
      ></span>
    </SearchButton>
  </div>
</template>

<script lang="ts">
  import { ClearSearchInput, SearchButton, SearchInput } from '@empathyco/x-components/search-box';
  import { CrossIcon } from '@empathyco/x-components';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';

  @Component({
    components: {
      ClearSearchInput,
      SearchButton,
      SearchInput,
      CrossIcon
    }
  })
  export default class SearchBox extends Vue {}
</script>
