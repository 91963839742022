






















import {
  animateClipPath,
  BaseEventsModalOpen,
  Dictionary,
  Primitive,
  RequestStatus,
  SnippetCallbacks,
  SnippetConfig,
  State,
  XOn,
  XProvide
} from '@empathyco/x-components';
import '@empathyco/x-components/design-system/full-theme.css';
import { DeviceDetector } from '@empathyco/x-components/device';
import { ExtraParams, SnippetConfigExtraParams } from '@empathyco/x-components/extra-params';
import { Tagging } from '@empathyco/x-components/tagging';
import { UrlHandler } from '@empathyco/x-components/url';
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import { adapter } from './adapter/adapter';
import Desktop from './components/desktop/desktop.vue';
import Mobile from './components/mobile/mobile.vue';
import AutoTopModal from './components/auto-top-modal.vue';
import './design-system/tokens.scss';
import currencies from './i18n/currencies';
import { ContentType, ContentTypeSearchState } from './x-modules/content-types';

@Component({
  components: {
    Mobile,
    Desktop,
    AutoTopModal,
    BaseEventsModalOpen,
    DeviceDetector,
    ExtraParams,
    SnippetCallbacks,
    SnippetConfigExtraParams,
    Tagging,
    UrlHandler
  }
})
export default class Layer extends Vue {
  protected modalAnimation = animateClipPath();

  protected openEvents = ['UserClickedOpenEventsModal', 'UserOpenXProgrammatically'];

  protected breakpoints: Dictionary<number> = {
    mobile: 600,
    desktop: Number.POSITIVE_INFINITY
  };

  @Inject('snippetConfig')
  protected snippetConfig!: SnippetConfig;

  protected get documentDirection(): string {
    return (
      document.documentElement.dir ||
      document.body.dir ||
      (this.snippetConfig.documentDirection ?? 'ltr')
    );
  }

  @XProvide('currencyFormat')
  public get currencyFormat(): string {
    return currencies[this.snippetConfig.currency!];
  }

  @Watch('snippetConfig.lang')
  syncLang(lang: string): void {
    this.$setLocale(lang);
    this.syncSearchLang(lang);
  }

  @Watch('snippetConfig.searchLang')
  syncSearchLang(searchLang: string): void {
    adapter.setConfig({
      requestParams: {
        lang: searchLang
      }
    });
  }

  @State('contentTypes', 'results')
  public contentTypeResults!: Record<ContentType, ContentTypeSearchState>;

  @State('contentTypes', 'contentTypes')
  public contentTypes!: ContentType[];

  @State('contentTypes', 'status')
  public contentTypesStatus!: RequestStatus;

  @XProvide('noResultsAtAll')
  public get noResultsAtAll(): boolean {
    return (
      this.$x.noResults &&
      !this.$x.partialResults.length &&
      this.contentTypesStatus !== 'loading' &&
      !Object.values(this.contentTypeResults).some(({ totalResults }) => totalResults > 0)
    );
  }

  @XOn(['UserClickedCloseEventsModal', 'UserClickedOutOfEventsModal'])
  clearQuery(): void {
    this.$x.emit('UserClearedQuery', '');
    this.$x.emit('UserPressedClearSearchBoxButton');
  }

  protected get extraParams(): Dictionary<Primitive> {
    return {
      contentType: this.contentTypes[0]
    };
  }
}
