<template>
  <div v-if="noResultsHtml" v-html="noResultsHtml"></div>
</template>

<script lang="ts">
  import { XOn } from '@empathyco/x-components';
  import Vue from 'vue';
  import { Component, Watch } from 'vue-property-decorator';

  @Component
  export default class NoResultsLinks extends Vue {
    protected noResultsHtml = '';

    @Watch('$x.noResults', { immediate: true })
    emitNoResultsEvent(isNoResults: boolean): void {
      if (isNoResults) {
        this.$x.emit('NoResultsShowed', this.$x.query.search);
      }
    }

    @XOn('SnippetCallbackExecuted')
    getNoResultsCallbackHtml({
      event,
      callbackReturn
    }: {
      event: string;
      callbackReturn: unknown;
    }): void {
      if (event === 'NoResultsShowed') {
        (callbackReturn as Promise<string>).then(html => {
          this.noResultsHtml = html;
        });
      }
    }
  }
</script>

<style scoped lang="scss"></style>
