

































import { BaseRatingFilterLabel } from '@empathyco/x-components';
import { FacetFilter } from '@empathyco/x-types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomPriceFilterLabel from './custom-price-filter-label.vue';

@Component({
  components: {
    BaseRatingFilterLabel,
    CustomPriceFilterLabel
  }
})
export default class FacetSelectedFilters extends Vue {
  @Prop({ required: true })
  public selectedFilters!: FacetFilter[];

  protected get isPriceFacet(): boolean {
    return this.selectedFilters[0]?.facetId === 'facetPrice';
  }
  protected get isRatingFacet(): boolean {
    return this.selectedFilters[0]?.facetId === 'avgOverallRating';
  }
}
