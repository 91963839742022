<template>
  <Empathize
    class="x-list x-shadow--01 x-background--neutral-100"
    :class="{
      'x-padding--left-05 x-padding--right-05 x-list__item--expand': $x.device === 'mobile',
      'x-padding--06 x-padding--06 x-padding--bottom': $x.device === 'desktop'
    }"
    :animation="empathizeAnimation"
    :eventsToCloseEmpathize="eventsToCloseEmpathize"
  >
    <BaseKeyboardNavigation
      class="x-list__item--expand"
      :navigationHijacker="navigationHijacker"
      :class="{
        'x-list x-list--vertical x-list--gap-06': $x.device === 'mobile',
        'x-row x-row--gap-06 x-row--align-start': $x.device === 'desktop'
      }"
    >
      <div
        class="
          x-predictive-layer__content
          x-list x-list__vertical x-list--gap-04
          x-row__item x-row__item--span-3
        "
        :class="{ 'x-icon--l': $x.device === 'mobile' }"
      >
        <!-- NEXT QUERIES -->
        <div
          v-if="
            $x.nextQueries.length > 0 &&
            (!$x.query.searchBox || $x.query.searchBox === $x.query.search)
          "
          class="x-list x-list--gap-03"
        >
          <h1 class="x-text x-text--bold x-padding--top-05">{{ $t('nextQueries.title') }}</h1>
          <CustomNextQueries class="x-list--gap-04" />
        </div>

        <!-- HISTORY QUERIES -->
        <div
          v-if="
            !$x.query.searchBox && $x.historyQueries.length > 0 && $x.identifierResults.length === 0
          "
          class="x-list x-list--align-end"
          :class="{
            'x-list--gap-03': $x.device === 'mobile',
            'x-list--gap-04': $x.device === 'desktop'
          }"
        >
          <h1 class="x-text x-text--bold x-list__item--stretch">
            {{ $t('historyQueries.title') }}
          </h1>
          <CustomHistoryQueries class="x-list--gap-04 x-list__item--stretch" />

          <ClearHistoryQueries
            v-if="!$x.query.searchBox"
            class="x-button--ghost x-button--ghost-end x-font-color--lead"
          >
            <span>{{ $t('historyQueries.clear') }}</span>
            <CrossTinyIcon />
          </ClearHistoryQueries>
        </div>

        <!-- POPULAR SEARCHES -->

        <div
          v-if="
            $x.popularSearches.length > 0 &&
            $x.nextQueries.length === 0 &&
            $x.identifierResults.length === 0 &&
            (!$x.query.searchBox || !resultSuggestions.length)
          "
          class="x-list"
          :class="{
            'x-list--gap-03 x-padding--top-05': $x.device === 'mobile',
            'x-list--gap-04': $x.device === 'desktop'
          }"
        >
          <h1 class="x-text x-text--bold">{{ $t('popularSearches.title') }}</h1>

          <CustomPopularSearches class="x-list--gap-04" />
        </div>

        <!-- QUERY SUGGESTIONS && HISTORY SUGGESTIONS -->
        <div
          v-if="$x.query.searchBox && $x.identifierResults.length === 0"
          class="x-list x-list--gap-04"
        >
          <CustomHistoryQueries class="x-list--gap-04 x-list__item--stretch" />
          <CustomQuerySuggestions class="x-row__item x-row__item--span-4 x-list x-list--gap-04" />
        </div>

        <!-- CATEGORIES SUGGESTIONS -->
        <div
          v-if="$x.query.searchBox && $x.identifierResults.length === 0 && categories.length"
          class="x-list"
          :class="$x.device === 'mobile' ? 'x-list--gap-06' : 'x-list--gap-04'"
        >
          <h1 class="x-font-weight--bold" :class="$x.device === 'mobile' ? 'x-title3' : 'x-text'">
            {{ $t('categories.title') }}
          </h1>

          <CategoriesSuggestions
            class="x-row__item x-row__item--span-4 x-list"
            :class="$x.device === 'mobile' ? 'x-list--gap-06' : 'x-list--gap-04'"
          />
        </div>

        <!-- IDENTIFIER RESULTS -->
        <IdentifierSuggestions class="x-list--gap-04" />
      </div>

      <div
        v-if="
          $x.device === 'desktop' &&
          !$x.identifierResults.length &&
          ($x.recommendations.length || resultSuggestions.length)
        "
        class="x-list x-list--gap-07 x-padding--07-bottom x-row__item x-row__item--span-9"
      >
        <template v-if="!resultSuggestions.length">
          <h1
            v-if="
              resultSuggestionsQuery &&
              resultSuggestionsStatus === 'success' &&
              redirections.length === 0
            "
            v-html="$t('resultSuggestions.noResultsTitle', { query: resultSuggestionsQuery })"
            class="x-text x-text--bold"
          />

          <CustomRecommendations maxItemsToRender="6" columns="3" />
        </template>
        <ResultSuggestions v-else class="x-row__item x-row__item--span-9" />
      </div>
    </BaseKeyboardNavigation>
  </Empathize>
</template>

<script lang="ts">
  import {
    animateClipPath,
    BaseKeyboardNavigation,
    CrossTinyIcon,
    RequestStatus,
    StaggeredFadeAndSlide,
    State,
    TakeNavigationControl,
    XEvent
  } from '@empathyco/x-components';
  import { Empathize } from '@empathyco/x-components/empathize';
  import { ClearHistoryQueries } from '@empathyco/x-components/history-queries';
  import { Result, Redirection } from '@empathyco/x-types';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import { Category } from '../../x-modules/categories';
  // eslint-disable-next-line max-len
  import ResultSuggestions from '../../x-modules/result-suggestions/components/result-suggestions.vue';
  import CustomRecommendations from '../results/custom-recommendations.vue';
  import CategoriesSuggestions from './categories-suggestions.vue';
  import CustomHistoryQueries from './custom-history-queries.vue';
  import CustomNextQueries from './custom-next-queries.vue';
  import CustomPopularSearches from './custom-popular-searches.vue';
  import CustomQuerySuggestions from './custom-query-suggestions.vue';
  import IdentifierSuggestions from './identifier-suggestions.vue';

  @Component({
    components: {
      BaseKeyboardNavigation,
      CategoriesSuggestions,
      ClearHistoryQueries,
      CrossTinyIcon,
      CustomHistoryQueries,
      CustomNextQueries,
      CustomPopularSearches,
      CustomQuerySuggestions,
      CustomRecommendations,
      Empathize,
      IdentifierSuggestions,
      ResultSuggestions
    }
  })
  export default class PredictiveLayer extends Vue {
    public suggestionsAnimation = StaggeredFadeAndSlide;
    public empathizeAnimation = animateClipPath();
    @State('categories', 'categories')
    public categories!: Category[];
    @State('resultSuggestions', 'resultSuggestions')
    public resultSuggestions!: Result[];
    @State('resultSuggestions', 'redirections')
    public redirections!: Redirection[];
    @State('resultSuggestions', 'status')
    public resultSuggestionsStatus!: RequestStatus;
    @State('resultSuggestions', 'query')
    public resultSuggestionsQuery!: string;

    // TODO Remove after https://searchbroker.atlassian.net/browse/EX-5230
    protected navigationHijacker: TakeNavigationControl[] = [
      {
        xEvent: 'UserPressedArrowKey',
        direction: 'ArrowDown',
        moduleName: 'scroll'
      }
    ];

    protected eventsToCloseEmpathize: XEvent[] = [
      'UserClosedEmpathize',
      'UserSelectedASuggestion',
      'UserAcceptedAQuery',
      'UserBlurredSearchBox',
      'UserScrolled'
    ];
  }
</script>

<style lang="scss">
  .x-mobile .x-predictive-layer__content > * + * {
    border-top: solid var(--x-size-border-width-base) var(--x-color-base-neutral-70);
    padding-top: var(--x-size-base-04);
  }
</style>
