<template>
  <div v-if="selectedFilters.length" class="x-ellipsis">
    <span
      v-if="$x.device === 'mobile' || selectedFilters.length >= 2"
      class="
        x-small
        x-background--lead
        x-font-color--neutral-100
        x-border-radius--pill
        x-padding--left-04 x-padding--right-04 x-padding--top-01 x-padding--bottom-01
      "
    >
      {{ selectedFilters.length }}
    </span>
    <template v-else>
      <CustomPriceFilterLabel
        v-if="isPriceFacet"
        :filter="selectedFilters[0]"
        class="x-text x-font-color--lead"
      />
      <BaseRatingFilterLabel
        v-else-if="isRatingFacet"
        :max="5"
        :filter="selectedFilters[0]"
        class="x-text x-font-color--lead"
      />
      <span v-else class="x-text x-font-color--lead">
        {{ selectedFilters[0].label }}
      </span>
    </template>
  </div>
</template>

<script lang="ts">
  import { BaseRatingFilterLabel } from '@empathyco/x-components';
  import { FacetFilter } from '@empathyco/x-types';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import CustomPriceFilterLabel from './custom-price-filter-label.vue';

  @Component({
    components: {
      BaseRatingFilterLabel,
      CustomPriceFilterLabel
    }
  })
  export default class FacetSelectedFilters extends Vue {
    @Prop({ required: true })
    public selectedFilters!: FacetFilter[];

    protected get isPriceFacet(): boolean {
      return this.selectedFilters[0]?.facetId === 'facetPrice';
    }
    protected get isRatingFacet(): boolean {
      return this.selectedFilters[0]?.facetId === 'avgOverallRating';
    }
  }
</script>
