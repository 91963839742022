









import { StaggeredFadeAndSlide, TrendingTinyIcon } from '@empathyco/x-components';
import { PopularSearches } from '@empathyco/x-components/popular-searches';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    PopularSearches,
    TrendingTinyIcon
  }
})
export default class CustomPopularSearches extends Vue {
  public suggestionsAnimation = StaggeredFadeAndSlide;
}
