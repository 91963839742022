<template>
  <MainScrollItem :item="result" tag="article" class="x-list x-list--vertical x-list--gap-04">
    <!--  LABELS - IMAGE - FLEX CAMPAIGNS  -->
    <div class="x-list x-list--horizontal x-list--align-start x-list--gap-02 x-list__item--expand">
      <slot name="labels"></slot>
      <div class="x-flex-1 x-margin--left-auto x-margin--right-auto">
        <slot name="image" />
      </div>
      <div class="x-list x-list--vertical x-list--gap-02" style="max-width: 55px">
        <slot name="flex-campaign" />
      </div>
    </div>

    <!--   NAME   -->
    <div>
      <slot name="name" />
      <slot name="id" />
    </div>

    <div class="x-row x-row--align-start">
      <div
        class="
          x-relative
          x-row__item x-row__item--span-6
          x-list x-list--vertical x-list--gap-05
          x-margin--right-05
        "
      >
        <div class="x-list x-list--vertical x-list--gap-05">
          <slot name="rating" />
          <slot name="energy" />
        </div>
      </div>

      <div
        class="x-relative x-row__item x-row__item--span-6 x-list x-list--vertical x-list--align-end"
      >
        <slot name="original-price" />
        <slot name="price" />
        <div style="text-align: right">
          <slot name="promotion" />
        </div>
      </div>
    </div>
    <slot name="stock" />

    <slot />
    <div class="x-list x-list--vertical x-list--gap-03 x-margin--top-auto">
      <slot name="add-to-cart" />
    </div>
  </MainScrollItem>
</template>

<script lang="ts">
  import { MainScrollItem } from '@empathyco/x-components/scroll';
  import { Result } from '@empathyco/x-types';
  import Vue from 'vue';
  import { Component, Prop } from 'vue-property-decorator';

  @Component({
    components: { MainScrollItem }
  })
  export default class MobileResultLayout extends Vue {
    @Prop()
    public result!: Result;
  }
</script>
