<template>
  <NoElement>
    <slot v-bind="{ items }" />
  </NoElement>
</template>

<script lang="ts">
  import {
    Getter,
    ItemsListInjectionMixin,
    ListItem,
    NoElement,
    objectFilter,
    reduce,
    State
  } from '@empathyco/x-components';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { ContentType, ContentTypeSearchState } from '../store/types';
  import { ContentTypeListItem } from './types';

  @Component({
    components: {
      NoElement
    }
  })
  export default class ContentTypesCarouselsList extends ItemsListInjectionMixin {
    @Prop({ default: 24 })
    public everyNthItems!: number;

    @State('contentTypes', 'results')
    public results!: Record<ContentType, ContentTypeSearchState>;

    @Getter('contentTypes', 'activeContentType')
    public activeContentType!: ContentType;

    protected get contentTypeListItems(): ContentTypeListItem[] {
      return reduce(
        // Exclude carousels of the active content type or without results
        objectFilter(
          this.results,
          (contentType, { totalResults }) =>
            totalResults > 0 &&
            contentType !== this.activeContentType &&
            contentType !== 'service' &&
            this.activeContentType !== 'spareparts'
        ),
        (listItems, contentType, { results, spellcheckedQuery }) => {
          listItems.push({
            id: `${contentType}Carousel`,
            modelName: 'ContentTypeCarousel',
            contentType,
            spellcheckedQuery,
            results
          });
          return listItems;
        },
        [] as ContentTypeListItem[]
      );
    }

    public override get items(): ListItem[] {
      if (this.injectedListItems?.length) {
        const items = [...this.injectedListItems];
        items.splice(24, 0, ...this.contentTypeListItems);
        return items;
      } else {
        return this.contentTypeListItems;
      }
    }
  }
</script>
