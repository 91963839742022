








































































import {
  animateScale,
  ArrowLeftIcon,
  BaseEventsModalClose,
  BaseIdModalOpen,
  FiltersIcon,
  Getter,
  LocationProvider,
  SingleColumnLayout,
  XInject
} from '@empathyco/x-components';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ContentType } from '../../x-modules/content-types/store/types';
import CustomRelatedTags from '../custom-related-tags.vue';
import CustomScrollToTop from '../custom-scroll-to-top.vue';
import Main from '../main.vue';
import Empathize from '../predictive-layer/predictive-layer.vue';
import SearchBox from '../search-box.vue';
import MobileAside from './mobile-aside.vue';
import MobileToolbar from './mobile-toolbar.vue';

@Component({
  components: {
    ArrowLeftIcon,
    BaseIdModalOpen,
    Close: BaseEventsModalClose,
    CollapseFromTop: animateScale(),
    CustomRelatedTags,
    CustomScrollToTop,
    Empathize,
    FiltersIcon,
    LocationProvider,
    Main,
    MobileAside,
    MobileToolbar,
    SearchBox,
    SingleColumnLayout
  }
})
export default class Mobile extends Vue {
  @XInject('noResultsAtAll')
  public noResultsAtAll!: boolean;

  @Getter('contentTypes', 'activeContentType')
  public selectedContentType!: ContentType;

  protected get showFacetsAndSort(): boolean {
    return this.selectedContentType === 'products' || this.selectedContentType === 'service';
  }
}
