<template>
  <ResultsList v-if="$x.totalResults" v-infinite-scroll:main-scroll>
    <ContentTypesCarouselsList>
      <BannersList>
        <PromotedsList>
          <component :is="grid" :columns="1" class="x-grid" :animation="staggeredFadeAndSlide">
            <template #result="{ item: result }">
              <ProductResult v-if="result.contentType === 'products'" :result="result" />
              <SimplifiedProductResult
                v-else-if="result.contentType === 'service'"
                :result="result"
              />
              <SparePartResult v-else-if="result.contentType === 'spareparts'" :result="result" />
              <ContentResult v-else :result="result" />
            </template>
            <template #banner="{ item: banner }">
              <MainScrollItem :item="banner" tag="article">
                <Banner :banner="banner" />
              </MainScrollItem>
            </template>
            <template #promoted="{ item: promoted }">
              <MainScrollItem :item="promoted" tag="article">
                <Promoted :promoted="promoted" />
              </MainScrollItem>
            </template>
            <template #content-type-carousel="{ item: contentTypesItem }">
              <MainScrollItem :item="contentTypesItem">
                <ContentTypeCarousel :item="contentTypesItem" />
              </MainScrollItem>
            </template>
          </component>
        </PromotedsList>
      </BannersList>
    </ContentTypesCarouselsList>
  </ResultsList>
</template>

<script lang="ts">
  import {
    BaseGrid,
    BaseVariableColumnGrid,
    infiniteScroll,
    StaggeredFadeAndSlide
  } from '@empathyco/x-components';
  import { MainScrollItem } from '@empathyco/x-components/scroll';
  import {
    Banner,
    BannersList,
    Promoted,
    PromotedsList,
    ResultsList
  } from '@empathyco/x-components/search';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  // eslint-disable-next-line max-len
  import ContentTypeCarousel from '../../x-modules/content-types/components/content-type-carousel.vue';
  // eslint-disable-next-line max-len
  import ContentTypesCarouselsList from '../../x-modules/content-types/components/content-types-carousels-list.vue';
  import ContentResult from '../result/content-result.vue';
  import ProductResult from '../result/product-result.vue';
  import SimplifiedProductResult from '../result/simplified-product-result.vue';
  import SparePartResult from '../result/spare-part-result.vue';

  @Component({
    components: {
      Banner,
      BannersList,
      BaseGrid,
      BaseVariableColumnGrid,
      ContentResult,
      ContentTypeCarousel,
      ContentTypesCarouselsList,
      MainScrollItem,
      Promoted,
      PromotedsList,
      ProductResult,
      ResultsList,
      SimplifiedProductResult,
      SparePartResult
    },
    directives: {
      'infinite-scroll': infiniteScroll
    }
  })
  export default class CustomResultsList extends Vue {
    protected staggeredFadeAndSlide = StaggeredFadeAndSlide;

    protected get grid(): string {
      return this.$x.device === 'mobile' ? 'BaseGrid' : 'BaseVariableColumnGrid';
    }
  }
</script>

<style lang="scss">
  .x-base-grid {
    &__content-type-carousel {
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }
</style>
