











































































































































import {
  animateClipPath,
  BaseKeyboardNavigation,
  CrossTinyIcon,
  RequestStatus,
  StaggeredFadeAndSlide,
  State,
  TakeNavigationControl,
  XEvent
} from '@empathyco/x-components';
import { Empathize } from '@empathyco/x-components/empathize';
import { ClearHistoryQueries } from '@empathyco/x-components/history-queries';
import { Result, Redirection } from '@empathyco/x-types';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Category } from '../../x-modules/categories';
// eslint-disable-next-line max-len
import ResultSuggestions from '../../x-modules/result-suggestions/components/result-suggestions.vue';
import CustomRecommendations from '../results/custom-recommendations.vue';
import CategoriesSuggestions from './categories-suggestions.vue';
import CustomHistoryQueries from './custom-history-queries.vue';
import CustomNextQueries from './custom-next-queries.vue';
import CustomPopularSearches from './custom-popular-searches.vue';
import CustomQuerySuggestions from './custom-query-suggestions.vue';
import IdentifierSuggestions from './identifier-suggestions.vue';

@Component({
  components: {
    BaseKeyboardNavigation,
    CategoriesSuggestions,
    ClearHistoryQueries,
    CrossTinyIcon,
    CustomHistoryQueries,
    CustomNextQueries,
    CustomPopularSearches,
    CustomQuerySuggestions,
    CustomRecommendations,
    Empathize,
    IdentifierSuggestions,
    ResultSuggestions
  }
})
export default class PredictiveLayer extends Vue {
  public suggestionsAnimation = StaggeredFadeAndSlide;
  public empathizeAnimation = animateClipPath();
  @State('categories', 'categories')
  public categories!: Category[];
  @State('resultSuggestions', 'resultSuggestions')
  public resultSuggestions!: Result[];
  @State('resultSuggestions', 'redirections')
  public redirections!: Redirection[];
  @State('resultSuggestions', 'status')
  public resultSuggestionsStatus!: RequestStatus;
  @State('resultSuggestions', 'query')
  public resultSuggestionsQuery!: string;

  // TODO Remove after https://searchbroker.atlassian.net/browse/EX-5230
  protected navigationHijacker: TakeNavigationControl[] = [
    {
      xEvent: 'UserPressedArrowKey',
      direction: 'ArrowDown',
      moduleName: 'scroll'
    }
  ];

  protected eventsToCloseEmpathize: XEvent[] = [
    'UserClosedEmpathize',
    'UserSelectedASuggestion',
    'UserAcceptedAQuery',
    'UserBlurredSearchBox',
    'UserScrolled'
  ];
}
