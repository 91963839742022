<template>
  <SlidingPanel
    class="
      x-sliding-panel--buttons-overflow x-sliding-panel--show-buttons-on-hover
      x-list__item--expand
    "
    :showButtons="$x.device !== 'mobile'"
  >
    <RelatedTags
      class="x-list--gap-03"
      :class="{ 'x-margin--left-09 x-margin--right-05': $x.device === 'mobile' }"
      :highlightCurated="true"
      :animation="relatedTagsAnimation"
    >
      <template #sliding-panel-left-button>
        <ChevronLeftIcon />
      </template>

      <template #related-tag-content="{ relatedTag, isSelected, shouldHighlightCurated }">
        <TagIcon v-if="shouldHighlightCurated" />
        <CrossTinyIcon v-if="isSelected" />
        {{ relatedTag.tag }}
        <PlusIcon v-if="!isSelected" />
      </template>

      <template #sliding-panel-right-button>
        <ChevronRightIcon />
      </template>
    </RelatedTags>
  </SlidingPanel>
</template>

<script lang="ts">
  import {
    ChevronLeftIcon,
    ChevronRightIcon,
    CrossTinyIcon,
    PlusIcon,
    SlidingPanel,
    StaggeredFadeAndSlide,
    TagIcon
  } from '@empathyco/x-components';
  import { RelatedTags } from '@empathyco/x-components/related-tags';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';

  @Component({
    components: {
      ChevronLeftIcon,
      ChevronRightIcon,
      CrossTinyIcon,
      PlusIcon,
      RelatedTags,
      SlidingPanel,
      TagIcon
    }
  })
  export default class CustomRelatedTags extends Vue {
    protected relatedTagsAnimation = StaggeredFadeAndSlide;
  }
</script>

<style lang="scss" scoped>
  ::v-deep .x-sliding-panel__button {
    // Can't change the button kind as the `round` variant is hardcoded into the sliding-panel
    // Task to do it https://searchbroker.atlassian.net/browse/EX-5232
    border-radius: 0;
    border: none;
  }
</style>
