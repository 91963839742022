import { XModule } from '@empathyco/x-components';
import { resultSuggestionsEmitters } from './store/emitters';
import { resultSuggestionsXStoreModule } from './store/module';
import { ResultSuggestionsXStoreModule } from './store/types';
import { resultSuggestionsWiring } from './wiring';

/**
 * ResultSuggestions {@link XModule} alias.
 *
 * @public
 */
export type ResultSuggestionsXModule = XModule<ResultSuggestionsXStoreModule>;

/**
 * ResultSuggestions {@link XModule} implementation. This module is auto-registered as soon as you
 * import any component from the `resultSuggestions` entry point.
 *
 * @public
 */
export const resultSuggestionsXModule: ResultSuggestionsXModule = {
  name: 'resultSuggestions',
  storeModule: resultSuggestionsXStoreModule,
  storeEmitters: resultSuggestionsEmitters,
  wiring: resultSuggestionsWiring
};
