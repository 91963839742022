<template>
  <a :href="category.url" class="x-category x-suggestion x-no-underline">
    <slot v-bind="{ category }">
      <span class="x-category__query x-suggestion__query">{{ category.label }}</span>
    </slot>
  </a>
</template>

<script lang="ts">
  import { xComponentMixin } from '@empathyco/x-components';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Category } from '../store';
  import { categoriesXModule } from '../x-module';

  @Component({
    mixins: [xComponentMixin(categoriesXModule)]
  })
  export default class CategoryLink extends Vue {
    @Prop({ required: true })
    public category!: Category;
  }
</script>
