<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 192 192"
    :class="['x-icon'].concat(data.staticClass, data.class)"
    :style="data.style"
  >
    <!-- eslint-disable max-len -->
    <path
      vector-effect="non-scaling-stroke"
      d="M72 152h48v8H72v-8zm4 24h40v-8H76v8zm53.906-118.291C120.844 48.868 108.803 44 96
      44c-12.803 0-24.844 4.869-33.906 13.709C53.005 66.575 48 78.37 48 90.922c0 12.553 5.005
      24.348 14.094 33.215 1.395 1.36 2.74 2.479 4.042 3.56.448.373.903.751 1.367
      1.146.257.217.553.456.877.716 1.354 1.086 3.62 2.905 3.62
      4.44v10h8v-10c0-5.373-4.302-8.825-6.613-10.68-.26-.209-.5-.4-.706-.575-.487-.413-.963-.809-1.431-1.199-1.236-1.026-2.402-1.996-3.57-3.135C60.148 111.063
      56 101.301 56 90.923s4.148-20.14 11.68-27.488C75.24 56.061 85.298 52 96 52c10.704 0 20.76
      4.061 28.32 11.435 7.532 7.348 11.68 17.11 11.68 27.488s-4.148 20.14-11.68 27.488c-1.168
      1.139-2.334 2.109-3.57 3.135-.468.39-.944.786-1.431 1.2-.206.174-.445.365-.706.574-2.31
      1.855-6.613 5.307-6.613 10.68v10h8v-10c0-1.535 2.266-3.354
      3.62-4.44.324-.26.62-.499.876-.716.465-.395.92-.773 1.368-1.145 1.302-1.082 2.647-2.2
      4.042-3.561C138.995 115.27 144 103.476 144 90.923c0-12.552-5.005-24.348-14.094-33.215z"
    />
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
