<template functional>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    :class="['x-icon'].concat(data.staticClass, data.class)"
    :style="data.style"
  >
    <g id="Symbols" stroke="currentColor" fill="none" fill-rule="evenodd">
      <path
        d="M 18.021519,1.9148412 H 9.9928281 L 3.9713089,7.9363592 V 19.979397 c 0,1.103946
        0.903228,2.007173 2.007173,2.007173 H 18.021519 c 1.103945,0 2.007172,-0.903227
        2.007172,-2.007173 V 3.9220142 c 0,-1.103945 -0.903227,-2.007173
        -2.007172,-2.007173 z"
      />
      <path
        d="m 9.8732854,11.569959 c 0,0 0.5530346,-1.8747493 2.2510336,-1.9176758 1.124046,-0.028417
        2.193288,0.6301118 2.231839,1.9796988 C 14.418638,13.819307 12,14.207479 12,14.207479
        V 16.5"
      />
      <path d="m 12,17 v 1" />
    </g>
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
