


























































import { BaseRatingFilterLabel, CrossTinyIcon, SlidingPanel } from '@empathyco/x-components';
import {
  NumberRangeFilter,
  SelectedFiltersList,
  SimpleFilter
} from '@empathyco/x-components/facets';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import PriceFilterLabel from './custom-price-filter-label.vue';

@Component({
  components: {
    BaseRatingFilterLabel,
    CrossTinyIcon,
    PriceFilterLabel,
    NumberRangeFilter,
    SelectedFiltersList,
    SimpleFilter,
    SlidingPanel
  }
})
export default class SelectedFiltersComponent extends Vue {}
