<template>
  <div
    class="
      x-list x-list--wrap-reverse x-list--gap-06 x-list--align-center x-list--justify-end
      x-border-color--neutral-70
      x-border-width--bottom-01
    "
  >
    <ContentTypesSelector class="x-self-start x-margin--right-auto" />

    <DesktopSort v-if="showFacetsAndSort" class="x-margin--left-auto" />

    <ColumnPicker v-if="$x.totalResults" #default="{ column, isSelected }" :columns="columnsValues">
      <Grid2RowsIcon
        v-if="column === 1"
        :class="{ 'x-font-color--lead': isSelected }"
        class="x-icon--l"
      />
      <Grid2ColIcon
        v-if="column === 0"
        :class="{ 'x-font-color--lead': isSelected }"
        class="x-icon--l"
      />
    </ColumnPicker>

    <BaseIdModalOpen
      #default="{ isPanelOpen }"
      :disabled="!showFacetsAndSort"
      modalId="right-aside"
      class="x-button x-button--ghost x-button--ghost-start x-font-weight--regular"
      :class="{ 'x-font-color--neutral-70': !showFacetsAndSort }"
    >
      <div class="x-badge-container">
        <FiltersIcon class="x-icon--l" />
        <span v-if="$x.selectedFilters.length" class="x-badge">
          {{ $x.selectedFilters.length }}
        </span>
      </div>
      <span>{{ isPanelOpen ? $t('toggleAside.hideAside') : $t('toggleAside.showAside') }}</span>
    </BaseIdModalOpen>
  </div>
</template>

<script lang="ts">
  import {
    BaseColumnPickerList,
    BaseIdModalOpen,
    FiltersIcon,
    Getter,
    Grid2ColIcon,
    Grid2RowsIcon
  } from '@empathyco/x-components';
  import { Component, Vue } from 'vue-property-decorator';
  import { ContentType, ContentTypesSelector } from '../../x-modules/content-types';
  import DesktopSort from './desktop-sort.vue';

  @Component({
    components: {
      BaseIdModalOpen,
      ColumnPicker: BaseColumnPickerList,
      ContentTypesSelector,
      DesktopSort,
      FiltersIcon,
      Grid2ColIcon,
      Grid2RowsIcon
    }
  })
  export default class DesktopToolbar extends Vue {
    protected columnsValues: number[] = [0, 1];
    @Getter('contentTypes', 'activeContentType')
    public selectedContentType!: ContentType;

    protected get showFacetsAndSort(): boolean {
      return !!this.$x.totalResults && this.selectedContentType === 'products';
    }
  }
</script>
