





























import {
  animateScale,
  BaseHeaderTogglePanel,
  ChevronDownIcon,
  ChevronUpIcon
} from '@empathyco/x-components';
import { Facet, Filter } from '@empathyco/x-types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import FacetSelectedFilters from './facet-selected-filters.vue';

@Component({
  components: {
    BaseHeaderTogglePanel,
    ChevronDownIcon,
    ChevronUpIcon,
    FacetSelectedFilters
  }
})
export default class FacetComponent extends Vue {
  public collapseFromTop = animateScale();

  @Prop()
  public facet!: Facet;

  @Prop()
  public selectedFilters!: Filter[];
}
