import { Dictionary, FetchHttpClient, RequestOptions } from '@empathyco/x-adapter';
import { ContentType } from '../x-modules/content-types/store/types';
import { CustomApolloHttpClient } from './custom-apollo-http-client';

export class CustomHttpClient extends FetchHttpClient {
  private apolloClient = new CustomApolloHttpClient();

  private initX: any = window.initX;
  private brand: string = (this.initX['brand'] as string).toLowerCase();
  private channel: string = (this.initX['channel'] as string).toLowerCase();
  private catalogType: string = (this.initX['catalogType'] as string) ?? 'SHOP';
  private language: string = (this.initX['lang'] as string).toLowerCase();
  private country: string = this.initX['country'] as string;
  private locale = `${this.language}-${this.country}`;

  get<T>(
    endpoint: string,
    { contentType, query, ...params }: Dictionary = {},
    options?: RequestOptions
  ): Promise<T> {
    const productIndex = `${this.brand}-${this.channel}-products`;
    const enumberIndex = `${this.brand}-enumbers`;
    const contentIndex = `${this.brand}-${this.channel}-content`;
    const contextFilter = `${this.brand}-${this.channel}-${
      this.language
    }-${this.country.toLocaleLowerCase()}`;
    switch (contentType as ContentType) {
      case 'products':
        params = Object.assign({}, params, {
          locale: this.locale,
          catalogType: this.catalogType
        });
        break;
      case 'service':
        endpoint = endpoint.replace(productIndex, enumberIndex);
        break;
      case 'manualsAndFAQs':
        endpoint = endpoint.replace(productIndex, contentIndex);
        params = Object.assign({}, params, {
          filter: ['documentType:ium', 'documentType:faq', ...params.filter],
          context: contextFilter
        });
        break;
      case 'explore':
        endpoint = endpoint.replace(productIndex, contentIndex);
        params = Object.assign({}, params, {
          filter: ['documentType:other', ...params.filter],
          context: contextFilter
        });
        break;
      case 'spareparts':
        return this.apolloClient.get(contentType, query);
    }
    return super.get(endpoint, { query, ...params }, options);
  }
}
