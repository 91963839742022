<template>
  <BaseResultLink
    :result="result"
    :class="
      showDesktopOneColumnCard && !withinCarousel
        ? 'x-row x-row--gap-06'
        : 'x-list x-list--vertical x-list--gap-04'
    "
  >
    <div
      class="
        x-flex-auto
        x-picture--fixed-ratio
        x-padding--left-08 x-padding--right-08
        x-row__item x-row__item--span-3
      "
    >
      <BaseResultImage class="x-picture--fixed-ratio" :animation="imageAnimation" :result="result">
        <template #placeholder>
          <BasePlaceholderImage />
        </template>
        <template #fallback>
          <BaseFallbackImage />
        </template>
      </BaseResultImage>
    </div>
    <div class="x-list x-list--horizontal x-list--gap-02">
      <div
        class="x-list x-list--vertical x-list--gap-04 x-flex-auto x-row__item x-row__item--span-9"
      >
        <h1
          v-html="result.name"
          class="x-title2 x-font-weight--light x-ellipsis"
          style="font-size: 18px"
        />
        <h2 class="x-text x-text--bold x-flex-auto x-uppercase">{{ result.id }}</h2>
        <div
          v-if="result.contentType === 'service'"
          class="x-button x-button--primary x-button--animated"
        >
          {{ $t('serviceResultButton') }}
        </div>
      </div>
      <EnergyLabel :result="result" style="width: 80px" />
    </div>
  </BaseResultLink>
</template>

<script lang="ts">
  import {
    BaseFallbackImage,
    BasePlaceholderImage,
    BaseResultCurrentPrice,
    BaseResultImage,
    BaseResultLink,
    CrossFade
  } from '@empathyco/x-components';
  import { Component, Prop } from 'vue-property-decorator';
  import BaseResult from './base-result';
  import EnergyLabel from './energy-label.vue';

  @Component({
    components: {
      BaseFallbackImage,
      BasePlaceholderImage,
      BaseResultCurrentPrice,
      BaseResultImage,
      BaseResultLink,
      CrossFade,
      EnergyLabel
    }
  })
  export default class SimplifiedProductResult extends BaseResult {
    @Prop({ default: false })
    public withinCarousel!: boolean;
  }
</script>
