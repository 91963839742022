
















































import {
  BaseFallbackImage,
  BasePlaceholderImage,
  BaseResultCurrentPrice,
  BaseResultImage,
  BaseResultLink,
  CrossFade
} from '@empathyco/x-components';
import { Component, Prop } from 'vue-property-decorator';
import BaseResult from './base-result';
import EnergyLabel from './energy-label.vue';

@Component({
  components: {
    BaseFallbackImage,
    BasePlaceholderImage,
    BaseResultCurrentPrice,
    BaseResultImage,
    BaseResultLink,
    CrossFade,
    EnergyLabel
  }
})
export default class SimplifiedProductResult extends BaseResult {
  @Prop({ default: false })
  public withinCarousel!: boolean;
}
