<template>
  <FixedHeaderAndAsidesLayout
    class="x-desktop"
    :class="{ 'x-force-scroll x-background--neutral-100': $x.query.search }"
  >
    <template #header>
      <div class="x-list x-list__item--expand">
        <div
          class="
            x-search-box-container
            x-border-width--bottom-01
            x-list x-list--horizontal x-list--gap-07 x-list--align-stretch x-list__item--stretch
            x-border-color--lead
            x-background--neutral-95
          "
        >
          <Close class="x-button--ghost x-button--ghost-start x-button--ghost-end">
            <ArrowLeftIcon class="x-icon--l" />
          </Close>

          <SearchBox class="x-list__item--expand x-border-width--00" />
        </div>

        <div class="x-predictive-layer__container">
          <LocationProvider location="predictive_layer">
            <PredictiveLayer class="x-desktop__predictive-layer" />
          </LocationProvider>
        </div>
      </div>
    </template>

    <template #sub-header>
      <LocationProvider
        v-if="$x.relatedTags.length > 0 && selectedContentType === 'products'"
        location="predictive_layer"
      >
        <CustomRelatedTags class="x-margin--top-05 x-margin--bottom-02" />
      </LocationProvider>
    </template>

    <template #toolbar>
      <DesktopToolbar v-if="$x.query.search && !noResultsAtAll" class="x-margin--top-05" />
    </template>

    <template #right-aside>
      <DesktopAside />
    </template>

    <template #main>
      <LocationProvider location="results">
        <Main />
      </LocationProvider>
    </template>

    <template #scroll-to-top>
      <CustomScrollToTop />
    </template>
  </FixedHeaderAndAsidesLayout>
</template>

<script lang="ts">
  import {
    ArrowLeftIcon,
    BaseEventsModalClose,
    FixedHeaderAndAsidesLayout,
    Getter,
    LocationProvider,
    XInject
  } from '@empathyco/x-components';
  import { Empathize } from '@empathyco/x-components/empathize';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import { ContentType } from '../../x-modules/content-types/index';
  import CustomRelatedTags from '../custom-related-tags.vue';
  import CustomScrollToTop from '../custom-scroll-to-top.vue';
  import Main from '../main.vue';
  import PredictiveLayer from '../predictive-layer/predictive-layer.vue';
  import SearchBox from '../search-box.vue';
  import DesktopAside from './desktop-aside.vue';
  import DesktopToolbar from './desktop-toolbar.vue';

  @Component({
    components: {
      ArrowLeftIcon,
      Close: BaseEventsModalClose,
      CustomRelatedTags,
      CustomScrollToTop,
      DesktopAside,
      DesktopToolbar,
      Empathize,
      LocationProvider,
      Main,
      FixedHeaderAndAsidesLayout,
      PredictiveLayer,
      SearchBox
    }
  })
  export default class Desktop extends Vue {
    @XInject('noResultsAtAll')
    public noResultsAtAll!: boolean;

    @Getter('contentTypes', 'activeContentType')
    public selectedContentType!: ContentType;
  }
</script>

<style lang="scss" scoped>
  .x-desktop {
    transition: background-color ease-out 1s;
  }

  .x-predictive-layer__container {
    height: 0;
    overflow-y: visible;
  }

  .x-search-box-container:focus-within {
    background-color: var(--x-color-base-focus) !important;
  }

  .x-force-scroll {
    ::v-deep .x-layout__main {
      /* Force to have scroll always so the header can always be hidden by scrolling down.
          This should prevent certain cases with only a few results to appear clipped. */
      min-height: calc(100vh + 1px);
    }
  }
</style>
