import { XPlugin } from '@empathyco/x-components';
import { ContentTypesXStoreModule } from '../types';

export const search: ContentTypesXStoreModule['actions']['search'] = async (_context, request) => {
  const searchResponse = await XPlugin.adapter.search(request, { requestId: request.contentType });
  return {
    ...searchResponse,
    contentType: request.contentType
  };
};
