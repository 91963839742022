import { loadUrlParams } from './actions/load-url-params.action';
import { cancelSearchAndSave, searchAndSave } from './actions/search-and-save.action';
import { search } from './actions/search.action';
import { activeContentType } from './getters/active-content-type.getter';
import { query } from './getters/query.getter';
import { request } from './getters/request.getter';
import { ContentType, ContentTypesState, ContentTypesXStoreModule } from './types';

/**
 * {@link XStoreModule} For the contentTypes module.
 *
 * @internal
 */
export const contentTypesXStoreModule: ContentTypesXStoreModule = {
  state() {
    const contentTypes: ContentType[] = [
      'products',
      'manualsAndFAQs',
      'service',
      'explore',
      'spareparts'
    ];

    return {
      contentTypes,
      status: 'initial',
      query: '',
      results: contentTypes.reduce((results, contentType) => {
        results[contentType] = {
          results: [],
          totalResults: 0,
          spellcheckedQuery: ''
        };
        return results;
      }, {} as ContentTypesState['results']),
      relatedTags: [],
      params: {},
      config: {
        maxItemsToRequest: 8
      }
    };
  },
  getters: {
    activeContentType,
    request,
    query
  },
  mutations: {
    setResults(status, { results, contentType }) {
      status.results[contentType].results = results;
    },
    setTotalResults(status, { totalResults, contentType }) {
      status.results[contentType].totalResults = totalResults;
    },
    setSpellcheck(state, { query, contentType }) {
      state.results[contentType].spellcheckedQuery = query;
    },
    setQuery(state, query) {
      state.query = query;
    },
    setExtraParams(state, params) {
      state.params = params;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setRelatedTags(state, relatedTags) {
      state.relatedTags = relatedTags;
    }
  },
  actions: {
    search,
    loadUrlParams,
    searchAndSave,
    cancelSearchAndSave
  }
};
