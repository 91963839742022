











































import {
  BaseColumnPickerList,
  BaseIdModalOpen,
  FiltersIcon,
  Getter,
  Grid2ColIcon,
  Grid2RowsIcon
} from '@empathyco/x-components';
import { Component, Vue } from 'vue-property-decorator';
import { ContentType, ContentTypesSelector } from '../../x-modules/content-types';
import DesktopSort from './desktop-sort.vue';

@Component({
  components: {
    BaseIdModalOpen,
    ColumnPicker: BaseColumnPickerList,
    ContentTypesSelector,
    DesktopSort,
    FiltersIcon,
    Grid2ColIcon,
    Grid2RowsIcon
  }
})
export default class DesktopToolbar extends Vue {
  protected columnsValues: number[] = [0, 1];
  @Getter('contentTypes', 'activeContentType')
  public selectedContentType!: ContentType;

  protected get showFacetsAndSort(): boolean {
    return !!this.$x.totalResults && this.selectedContentType === 'products';
  }
}
