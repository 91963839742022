import { SearchRequest } from '@empathyco/x-adapter';
import { createFetchAndSaveActions } from '@empathyco/x-components';
import { ResultSuggestionsActionsContext, ResultSuggestionsSearchResponse } from '../types';

const { fetchAndSave, cancelPrevious } = createFetchAndSaveActions<
  ResultSuggestionsActionsContext,
  SearchRequest | null,
  ResultSuggestionsSearchResponse
>({
  fetch({ dispatch }, request) {
    return dispatch('fetchResultSuggestions', request);
  },
  onSuccess({ commit }, { results, redirections /*, spellcheck*/ }) {
    commit('setResultSuggestions', results);
    commit('setRedirections', redirections);
    /* Just in case they want to show spellcheck in Empathize before the accepted query */
    /*(commit as any)('x/search/setSpellcheck', spellcheck ?? '', { root: true });*/
  }
});

/**
 * Default implementation for {@link ResultSuggestionsActions.fetchAndSaveResultSuggestions}
 * action.
 *
 * @public
 */
export const fetchAndSaveResultSuggestions = fetchAndSave;

/**
 * Default implementation for {@link ResultSuggestionsActions.cancelFetchAndSaveResultSuggestions}
 * action.
 *
 * @public
 */
export const cancelFetchAndSaveResultSuggestions = cancelPrevious;
