<template>
  <div @click.stop class="x-relative x-list x-list--horizontal">
    <BaseAddToCart
      :result="result"
      v-bind="$attrs"
      :class="cssClass"
      class="x-list__item--expand"
      :disabled="!result.buyable"
    >
      <slot />
    </BaseAddToCart>
    <Popup v-model="open" class="x-campaign__popup" style="max-width: min(600px, 80vw)">
      <div v-html="popupHtml"></div>
    </Popup>
  </div>
</template>

<script lang="ts">
  import { BaseAddToCart, VueCSSClasses, XOn } from '@empathyco/x-components';
  import { Result } from '@empathyco/x-types';
  import Vue from 'vue';
  import { Component, Prop } from 'vue-property-decorator';
  import Popup from './popup.vue';

  @Component({
    inheritAttrs: false,
    components: {
      BaseAddToCart,
      Popup
    }
  })
  export default class CustomResultAddToCart extends Vue {
    @Prop()
    protected result!: Result;

    @Prop()
    protected cssClass!: VueCSSClasses;

    protected open = false;
    protected popupHtml = '';

    @XOn('SnippetCallbackExecuted')
    getAddToCartCallbackHtml({
      event,
      callbackReturn,
      payload
    }: {
      event: string;
      callbackReturn: unknown;
      payload: Result;
    }): void {
      if (event === 'UserClickedResultAddToCart' && payload === this.result) {
        (callbackReturn as Promise<string>).then(html => {
          this.popupHtml = html;
          this.open = true;
        });
      }
    }
  }
</script>
