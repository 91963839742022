









import { Component, Vue } from 'vue-property-decorator';
import { Spellcheck, SpellcheckButton } from '@empathyco/x-components/search';

@Component({
  components: {
    Spellcheck,
    SpellcheckButton
  }
})
export default class SpellcheckMessage extends Vue {}
