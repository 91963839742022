
































import { animateScale, CrossIcon, WireMetadata, XOn } from '@empathyco/x-components';
import Vue from 'vue';
import GlobalEvents from 'vue-global-events';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {
    CollapseFromTop: animateScale(),
    CrossIcon,
    GlobalEvents
  }
})
export default class Popup extends Vue {
  @Prop()
  protected value!: false;
  protected xPosition = 0;

  closePopup(): void {
    this.$emit('input', false);
  }

  @Watch('value')
  protected async calculatePopupPosition(open: boolean): Promise<void> {
    if (open) {
      this.xPosition = 0;
      await this.$nextTick();
      const { x, width } = (this.$refs.popup as HTMLElement).getBoundingClientRect();
      const screenPadding = 18;
      if (x < screenPadding) {
        this.xPosition = screenPadding - x;
      } else if (x + width > document.body.clientWidth - screenPadding) {
        this.xPosition = document.body.clientWidth - x - width - screenPadding;
      }
    }
  }

  @Watch('value')
  protected emitEvent(open: boolean): void {
    if (open) {
      this.$x.emit('UserOpenAPopup', undefined, { target: this.$el as HTMLElement });
    }
  }

  @XOn('UserOpenAPopup')
  closeOnOtherCampaignOpen(_: undefined, { target }: WireMetadata): void {
    if (target !== (this.$el as HTMLElement)) {
      this.closePopup();
    }
  }
}
