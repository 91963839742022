<template>
  <CategoriesList #category-content="{ category }">
    <ArrowRightIcon />
    <span class="x-suggestion__query">{{ category.label }}</span>
  </CategoriesList>
</template>

<script lang="ts">
  import { ArrowRightIcon } from '@empathyco/x-components';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import CategoriesList from '../../x-modules/categories/components/categories-list.vue';

  @Component({
    components: {
      CategoriesList,
      ArrowRightIcon
    }
  })
  export default class Categories extends Vue {}
</script>
