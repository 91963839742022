























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Sort } from '@empathyco/x-types';
import { SortList } from '@empathyco/x-components/search';
import {
  BaseHeaderTogglePanel,
  CheckTinyIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronTinyDownIcon,
  State,
  animateScale
} from '@empathyco/x-components';

@Component({
  components: {
    SortList,
    CheckTinyIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    ChevronTinyDownIcon,
    BaseHeaderTogglePanel
  }
})
export default class MobileSort extends Vue {
  protected collapseFromTop = animateScale();
  public sortValues: Sort[] = ['', 'price asc', 'price desc'];

  @State('search', 'sort')
  public sortValue!: string;
}
