

































































import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Getter,
  ItemsListInjectionMixin,
  SlidingPanel
} from '@empathyco/x-components';
import { RenderlessExtraParams } from '@empathyco/x-components/extra-params';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ContentResult from '../../../components/result/content-result.vue';
import SimplifiedProductResult from '../../../components/result/simplified-product-result.vue';
import { ContentType } from '../store/types';
import { ContentTypeListItem } from './types';

@Component({
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    ContentResult,
    SimplifiedProductResult,
    SlidingPanel,
    RenderlessExtraParams
  }
})
export default class ContentTypeCarousel extends ItemsListInjectionMixin {
  @Prop({ required: true })
  public item!: ContentTypeListItem;

  @Getter('contentTypes', 'activeContentType')
  public activeContentType!: ContentType;
}
