import { XModule } from '@empathyco/x-components';
import { categoriesEmitters } from './store/emitters';
import { categoriesXStoreModule } from './store/module';
import { CategoriesXStoreModule } from './store/types';
import { categoriesWiring } from './wiring';

/**
 * Categories {@link XModule} alias.
 *
 * @public
 */
export type CategoriesXModule = XModule<CategoriesXStoreModule>;

/**
 * Categories {@link XModule} implementation. This module is auto-registered as soon as you
 * import any component from the `categories` entry point.
 *
 * @public
 */
export const categoriesXModule: CategoriesXModule = {
  name: 'categories',
  storeModule: categoriesXStoreModule,
  storeEmitters: categoriesEmitters,
  wiring: categoriesWiring
};
