<template>
  <Redirection
    #default="{ redirection, redirect, abortRedirect, isRedirecting, delayInSeconds }"
    class="x-margin--top-03 x-margin--bottom-03"
    delayInSeconds="3"
  >
    <p>
      {{ $t('redirections.title') }}
    </p>
    <a :href="redirection.url">{{ redirection.url }}</a>
    <div class="x-list x-list--horizontal x-list--gap-07 x-margin--top-03">
      <button @click="abortRedirect" class="x-button x-button--ghost x-font-color--neutral-70">
        {{ $t('redirections.reject') }}
      </button>
      <button @click="redirect" class="x-button x-button--ghost x-font-color--neutral-10">
        {{ $t('redirections.accept') }}
      </button>
    </div>
    <AutoProgressBar :isLoading="isRedirecting" :durationInSeconds="delayInSeconds" />
  </Redirection>
</template>

<script lang="ts">
  import { AutoProgressBar } from '@empathyco/x-components';
  import { Redirection } from '@empathyco/x-components/search';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';

  @Component({ components: { AutoProgressBar, Redirection } })
  export default class CustomRedirection extends Vue {}
</script>

<style scoped lang="scss"></style>
