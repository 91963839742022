<template>
  <div
    v-if="$x.totalResults > 0"
    class="x-list x-list--vertical x-list__item--expand x-background--neutral-100"
  >
    <!-- ASIDE HEADER -->
    <div class="x-list x-list--horizontal x-background--lead">
      <span class="x-title2 x-margin--right-auto x-padding--05 x-font-color--neutral-100">
        {{ $t('facetsPanel.title') }}
      </span>
      <BaseIdModalClose modalId="aside-modal" class="x-button--ghost x-font-color--neutral-100">
        <CrossIcon />
      </BaseIdModalClose>
    </div>

    <!-- ASIDE BODY -->
    <div class="x-scroll x-list x-list__item--expand">
      <MobileSort class="x-list x-padding--05 x-padding--bottom-01 x-padding--top-01" />
      <CustomFacets class="x-list" />
    </div>

    <!-- ASIDE FOOTER -->
    <div
      class="
        x-list x-list--vertical
        x-padding--05
        x-border-width--top-01
        x-border-color--neutral-70
      "
    >
      <SelectedFilters
        v-if="$x.selectedFilters.length"
        class="x-list x-list--gap-05 x-padding--bottom-05"
      />
      <div class="x-row x-row--gap-06 x-list__item--stretch">
        <ClearFilters
          #default="{ selectedFilters }"
          class="x-button--secondary x-row__item x-row__item--span-6"
          :alwaysVisible="false"
        >
          {{ $t('selectedFilters.clear', { selectedFiltersNumber: selectedFilters.length }) }}
        </ClearFilters>
        <BaseIdModalClose
          modalId="aside-modal"
          class="x-button x-button--animated x-row__item"
          :class="$x.selectedFilters.length ? 'x-row__item--span-6' : 'x-row__item--span-12'"
        >
          {{ $t('facetsPanel.viewResults', { totalResults: $x.totalResults }) }}
        </BaseIdModalClose>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { BaseIdModalClose, CrossIcon } from '@empathyco/x-components';
  import { ClearFilters } from '@empathyco/x-components/facets';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import CustomFacets from '../facets/custom-facets.vue';
  import SelectedFilters from '../facets/selected-filters.vue';
  import MobileSort from './mobile-sort.vue';

  @Component({
    components: {
      BaseIdModalClose,
      ClearFilters,
      CrossIcon,
      CustomFacets,
      MobileSort,
      SelectedFilters
    }
  })
  export default class MobileAside extends Vue {}
</script>
