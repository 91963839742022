import { ContentTypesXStoreModule } from '../types';

export const request: ContentTypesXStoreModule['getters']['request'] = (
  { params: { contentType, ...params }, config },
  { query }
) =>
  query
    ? {
        ...params,
        query,
        start: 0,
        rows: config.maxItemsToRequest
      }
    : null;
