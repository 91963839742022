import { ApolloClient, DefaultOptions } from 'apollo-client';
import { from, ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { v4 as uuid } from 'uuid';

import { ShopContext } from './apollo-bsh-types';

const GRAPHQL_PATHNAME = '/graphql';

const createContextForRequest = (shopContext: ShopContext): ApolloLink =>
  setContext((operation, previousContext) => {
    if (!operation.extensions) {
      throw Error('Apollo Link Extensions must be enabled');
    }

    const headers: any = {
      'x-operation': operation.operationName,
      'x-flow-id': uuid()
    };

    if (shopContext) {
      operation.extensions.shop = shopContext;
    }

    // it could happen that the "persistentQueryLink" disables the use of extensions
    // in this case we fallback to pass the context as variables.
    // Note: for this to work the HTTP link must be set to includeUnusedVariables: true
    if (previousContext?.http?.includeExtensions === false && operation.variables) {
      operation.variables.shop = shopContext;
    }
    return { headers };
  });

export const httpLinkClient = (uri: string, fetch_ = fetch): HttpLink =>
  new HttpLink({
    uri,
    includeExtensions: true,
    fetch: fetch_,
    credentials: 'same-origin'
  });

const createClient = ({
  links,
  defaultOptions,
  cache
}: {
  links: ApolloLink[];
  defaultOptions?: DefaultOptions;
  cache: InMemoryCache;
}): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    link: from(links),
    name: `empathy-archetype-pilot`,
    defaultOptions,
    version: '1.0.0',
    cache
  });

const apolloClientBrowser = ({
  shopContext
}: ApolloClientOptions): ApolloClient<NormalizedCacheObject> =>
  createClient({
    links: [createContextForRequest(shopContext), httpLinkClient(`${GRAPHQL_PATHNAME}`)],
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all'
      }
    },
    cache: new InMemoryCache()
  });

export type ApolloClientOptions = {
  shopContext: ShopContext;
};

let apolloClient: ApolloClient<NormalizedCacheObject>;
export const createApolloClient = (
  opts: ApolloClientOptions
): ApolloClient<NormalizedCacheObject> => {
  apolloClient ??= apolloClientBrowser(opts);
  return apolloClient;
};
