import { SearchRequest } from '@empathyco/x-adapter';
import { createFetchAndSaveActions } from '@empathyco/x-components';
import { ContentTypesActionContext, ContentTypesSearchResponse } from '../types';

export const { fetchAndSave: searchAndSave, cancelPrevious: cancelSearchAndSave } =
  createFetchAndSaveActions<
    ContentTypesActionContext,
    SearchRequest | null,
    ContentTypesSearchResponse[]
  >({
    fetch({ state, dispatch }, request) {
      return Promise.all(
        state.contentTypes.map(contentType => {
          return request
            ? dispatch('search', {
                ...request,
                contentType
              })
            : {
                results: [],
                totalResults: 0,
                spellcheck: '',
                contentType
              };
        })
      );
    },
    onSuccess({ commit }, responses) {
      responses.forEach(({ contentType, results, totalResults, spellcheck }) => {
        commit('setResults', { results, contentType });
        commit('setTotalResults', { totalResults, contentType });
        commit('setSpellcheck', { query: spellcheck ?? '', contentType });
      });
    }
  });
