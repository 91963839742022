<template>
  <MainScrollItem :item="result" tag="article" class="x-row x-row--align-stretch x-row--gap-04">
    <div class="x-row__item x-row__item--span-2">
      <div class="x-relative x-flex-no-shrink">
        <slot name="image" />
        <div class="x-absolute" style="top: 0">
          <slot name="labels"></slot>
        </div>
      </div>
    </div>

    <div class="x-row__item x-row__item--span-7 x-list x-list--gap-03 x-flex-1">
      <slot name="rating" />
      <slot name="name" />
      <slot name="id" />
      <slot name="description" />

      <div
        v-if="$slots['flex-campaign']"
        class="x-list x-list--horizontal x-list--gap-02 x-list--align-stretch"
        style="height: 60px"
      >
        <slot name="flex-campaign" />
      </div>
    </div>

    <div
      class="
        x-row__item x-row__item--span-3
        x-list x-list--vertical x-list--gap-03 x-list--align-start
      "
    >
      <slot name="energy" />
      <div class="x-list x-list--vertical">
        <slot name="price" />
        <slot name="original-price" />
      </div>
      <slot name="promotion" />
      <slot name="stock" />
      <div class="x-list x-list--vertical x-list__item--stretch x-list--gap-03">
        <slot name="add-to-cart" />
      </div>
    </div>
  </MainScrollItem>
</template>

<script lang="ts">
  import { MainScrollItem } from '@empathyco/x-components/scroll';
  import { Result } from '@empathyco/x-types';
  import Vue from 'vue';
  import { Component, Prop } from 'vue-property-decorator';

  @Component({
    components: { MainScrollItem }
  })
  export default class DesktopOneColumnResultLayout extends Vue {
    @Prop()
    public result!: Result;
  }
</script>
