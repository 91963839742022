




























































import {
  ArrowLeftIcon,
  BaseEventsModalClose,
  FixedHeaderAndAsidesLayout,
  Getter,
  LocationProvider,
  XInject
} from '@empathyco/x-components';
import { Empathize } from '@empathyco/x-components/empathize';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ContentType } from '../../x-modules/content-types/index';
import CustomRelatedTags from '../custom-related-tags.vue';
import CustomScrollToTop from '../custom-scroll-to-top.vue';
import Main from '../main.vue';
import PredictiveLayer from '../predictive-layer/predictive-layer.vue';
import SearchBox from '../search-box.vue';
import DesktopAside from './desktop-aside.vue';
import DesktopToolbar from './desktop-toolbar.vue';

@Component({
  components: {
    ArrowLeftIcon,
    Close: BaseEventsModalClose,
    CustomRelatedTags,
    CustomScrollToTop,
    DesktopAside,
    DesktopToolbar,
    Empathize,
    LocationProvider,
    Main,
    FixedHeaderAndAsidesLayout,
    PredictiveLayer,
    SearchBox
  }
})
export default class Desktop extends Vue {
  @XInject('noResultsAtAll')
  public noResultsAtAll!: boolean;

  @Getter('contentTypes', 'activeContentType')
  public selectedContentType!: ContentType;
}
