<template>
  <div
    v-if="result.energyLabelIcon || result.energyDatasheet"
    class="x-list x-list--wrap x-list--gap-02 x-list--align-center"
  >
    <a v-if="result.energyLabel" target="_blank" :href="result.energyLabel">
      <img :src="result.energyLabelIcon" alt="energy label" style="width: 70px" />
    </a>
    <a
      v-if="result.energyDatasheet"
      target="_blank"
      class="x-small x-font-color--lead"
      :href="result.energyDatasheet"
    >
      {{ $t('result.dataSheet') }}
      <!-- DON'T REMOVE: Classes `a-link-download` and `a-link` are from BSH. -->
      <span class="x-icon a-link-download a-link"></span>
    </a>
    <img
      v-if="!result.energyLabel && result.energyLabelIcon"
      :src="result.energyLabelIcon"
      alt="energy label"
      style="width: 70px"
    />
    <span v-if="result.energyClassRange" class="x-small" style="width: 100%">
      {{ result.energyClassRange }}
    </span>
  </div>
</template>

<script lang="ts">
  import { Component } from 'vue-property-decorator';
  import BaseResult from './base-result';

  @Component
  export default class EnergyLabel extends BaseResult {}
</script>
