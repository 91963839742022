<template>
  <SingleColumnLayout class="x-background--neutral-100">
    <template #header>
      <div
        class="x-list x-list--horizontal x-list__item--expand x-padding--05 x-list--align-center"
      >
        <Close class="x-button--ghost x-button--ghost-start x-padding--right-03 x-border-width--00">
          <ArrowLeftIcon class="x-icon--l" />
        </Close>
        <SearchBox class="x-list__item--expand" />
      </div>
    </template>
    <template #sub-header>
      <LocationProvider
        v-if="$x.relatedTags.length > 0 && selectedContentType === 'products'"
        location="predictive_layer"
      >
        <CustomRelatedTags />
      </LocationProvider>
    </template>
    <template #toolbar>
      <MobileToolbar v-if="$x.query.search && !noResultsAtAll" class="x-shadow--bottom-01" />
    </template>

    <template #predictive>
      <LocationProvider location="predictive_layer">
        <Empathize class="x-list x-background--neutral-100" />
      </LocationProvider>
    </template>

    <template #main>
      <LocationProvider location="results">
        <Main />
      </LocationProvider>
    </template>

    <template #floating>
      <div class="x-list x-list--vertical x-list--align-end x-list__item--expand">
        <CustomScrollToTop class="x-margin--bottom-03" />
        <CollapseFromTop>
          <BaseIdModalOpen
            v-if="$x.totalResults > 0 && showFacetsAndSort"
            modalId="aside-modal"
            class="x-list__item--stretch"
            style="transform-origin: bottom center"
          >
            <FiltersIcon />
            <span>{{ $t('toggleAside.showAside') }}</span>
            <span
              v-if="$x.selectedFilters.length"
              class="
                x-small
                x-font-color--neutral-100
                x-border-radius--20
                x-border-width--01
                x-border-color--neutral-100
              "
              style="width: 16px; height: 16px"
            >
              {{ $x.selectedFilters.length }}
            </span>
          </BaseIdModalOpen>
        </CollapseFromTop>
      </div>
    </template>

    <template #aside>
      <MobileAside />
    </template>
  </SingleColumnLayout>
</template>

<script lang="ts">
  import {
    animateScale,
    ArrowLeftIcon,
    BaseEventsModalClose,
    BaseIdModalOpen,
    FiltersIcon,
    Getter,
    LocationProvider,
    SingleColumnLayout,
    XInject
  } from '@empathyco/x-components';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';
  import { ContentType } from '../../x-modules/content-types/store/types';
  import CustomRelatedTags from '../custom-related-tags.vue';
  import CustomScrollToTop from '../custom-scroll-to-top.vue';
  import Main from '../main.vue';
  import Empathize from '../predictive-layer/predictive-layer.vue';
  import SearchBox from '../search-box.vue';
  import MobileAside from './mobile-aside.vue';
  import MobileToolbar from './mobile-toolbar.vue';

  @Component({
    components: {
      ArrowLeftIcon,
      BaseIdModalOpen,
      Close: BaseEventsModalClose,
      CollapseFromTop: animateScale(),
      CustomRelatedTags,
      CustomScrollToTop,
      Empathize,
      FiltersIcon,
      LocationProvider,
      Main,
      MobileAside,
      MobileToolbar,
      SearchBox,
      SingleColumnLayout
    }
  })
  export default class Mobile extends Vue {
    @XInject('noResultsAtAll')
    public noResultsAtAll!: boolean;

    @Getter('contentTypes', 'activeContentType')
    public selectedContentType!: ContentType;

    protected get showFacetsAndSort(): boolean {
      return this.selectedContentType === 'products' || this.selectedContentType === 'service';
    }
  }
</script>

<style lang="scss"></style>
