<template>
  <PopularSearches :animation="suggestionsAnimation" :max-items-to-render="4">
    <template #suggestion-content="{ suggestion }">
      <TrendingTinyIcon />
      <span>{{ suggestion.query }}</span>
    </template>
  </PopularSearches>
</template>

<script lang="ts">
  import { StaggeredFadeAndSlide, TrendingTinyIcon } from '@empathyco/x-components';
  import { PopularSearches } from '@empathyco/x-components/popular-searches';
  import Vue from 'vue';
  import { Component } from 'vue-property-decorator';

  @Component({
    components: {
      PopularSearches,
      TrendingTinyIcon
    }
  })
  export default class CustomPopularSearches extends Vue {
    public suggestionsAnimation = StaggeredFadeAndSlide;
  }
</script>
